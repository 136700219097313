import writeXlsxFile from "write-excel-file";

export const exportExcel = async (filename, state, statistics, records) => {
    const methods = {
      'CARD': 'Κάρτα',
      'CASH': 'Μετρητά'
    }

    const columns = [
        { width: 20 },
        { width: 20 },
        { width: 50 },
        { width: 12 },
        { width: 12 },
        { width: 12 },
        { width: 22 },
        { width: 12 },
        { width: 12 },
    ];

    const header = [
        { value: "Ημερομηνία", fontWeight: "bold" },
        { value: "Κατηγορία", fontWeight: "bold" },
        { value: "Οργανισμός", fontWeight: "bold" },
        { value: "Συσκευή", fontWeight: "bold" },
        { value: "Μέθοδος πληρωμής", fontWeight: "bold" },
        { value: "Κατάσταση", fontWeight: "bold" },
        { value: "Κωδικός συναλλαγής", fontWeight: "bold" },
        { value: "Ποσό", fontWeight: "bold" },
        { value: "Προμήθεια", fontWeight: "bold" },
    ];

    let data = [];
    for (let x = 0; x < records.length; ++x) {
        const item = records[x];
        data.push([
            { type: Date, value: new Date(item.timestamp), format: "dd/mm/yyyy hh:mm:ss" },
            { type: String, value: item.category },
            { type: String, value: item.organization },
            { type: String, value: item.serialNo },
            { type: String, value: methods[item.method] },
            { type: String, value: item.status },
            { type: String, value: item.clearingRef },
            { type: Number, value: item.amount, format: "0.00" },
            { type: Number, value: item.commission, format: "0.00" },
        ]);
    }

    const file = await writeXlsxFile([header, ...data], {
        columns,
        fileName: filename,
        fontSize: 10,
    });
    return file;
};
