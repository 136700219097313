import React from "react";
import { FormGroup, Label, Input } from "reactstrap";

function SelectFormField({ name, title, items, selected, setSelectedFn, disabled = false }) {
  return (
    <FormGroup>
      <Label for={name} size="sm">
        {title}
      </Label>
      <Input
        type="select"
        name={name}
        id={name}
        bsSize="sm"
        disabled={disabled}
        value={selected}
        onChange={(e) => setSelectedFn(e.target.value)}
      >
        {items?.map((text, index) => (
          <option key={index} value={index}>
            {text}
          </option>
        ))}
      </Input>
    </FormGroup>
  );
}

export default SelectFormField;
