import React, { useContext, useEffect, useState } from "react";
import { Context } from "../Store";
import { config, TriliaAppMode } from "../Config";
import "./footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useWebsockets } from "../useWebsockets";
import { Progress, UncontrolledTooltip } from "reactstrap";

function Footer() {
    const [state] = useContext(Context);
    const [lastInsertion, setLastInsertion] = useState("άγνωστο");
    const [queues, setQueues] = useState(null);

    const [connected, send, receive] = useWebsockets();

    receive("footer", (msg) => {
        if (msg.type === "events/rmq_queues") {
            setQueues(msg.payload)
        }
    });

    useEffect(
      () => {
        if (!TriliaAppMode && connected) {
          send("rmq_queues", null, (msg) => {
            // console.log(msg?.payload)
            if (msg?.payload) {
              setQueues(msg.payload)
            }
          })
        }
      }, [connected, state.access_token, send]
    );

    useEffect(
        () => {
            if (connected) {
                send("last_update", null, (msg) => setLastInsertion(msg.payload || "άγνωστο"))
            }
        }, [connected, state.access_token, state.database, send]
    );

    return (
        <>
            <div className="footer">
                <div className="footer-left">
                    &copy; 2023, Trilia{", "}
                    version <strong>{config.VERSION}</strong>
                </div>
                {!TriliaAppMode &&
                <div className="footer-middle">
                    <FontAwesomeIcon id="footerqconn" className={connected ? "" : "text-muted"} icon="globe" />
                    <UncontrolledTooltip target="footerqconn">
                        {connected ? "Συνδεδεμένο" : "Χωρίς σύνδεση..."}
                    </UncontrolledTooltip>
                    {queues &&
                        queues.map((q, idx) => {
                            return (q.progress < 100 &&
                                <Progress
                                    key={`${q.node}.${q.name}`}
                                    id={`footerpb${idx}`}
                                    value={q.progress}
                                    color="warning"
                                >
                                    <UncontrolledTooltip target={`footerpb${idx}`}>
                                        <h6>Φόρτωμα δεδομένων</h6>
                                        <div className="tooltip-container">
                                            <div className="tooltip-column" style={{ textAlign: "right" }}>
                                                <div>Ουρά:</div>
                                                <div>Πρόοδος:</div>
                                                <div>Ταχύτητα:</div>
                                            </div>
                                            <div className="tooltip-column" style={{ textAlign: "left" }}>
                                                <div>{q.name}</div>
                                                <div style={{ fontWeight: "bold" }}>{q.progress.toFixed(1)}%</div>
                                                <div>{q.avg_egress_rate.toFixed(1)} μην/δευ</div>
                                            </div>
                                        </div>
                                    </UncontrolledTooltip>
                                </Progress>
                            )
                        }
                        )
                    }
                </div>}
                <div className="footer-right">
                    {state.access_token && (
                        <>
                            Τελευταία ενημέρωση:{" "}
                            <strong>{lastInsertion}</strong>
                        </>
                    )}
                </div>
            </div>
        </>
    );
}

export default Footer;
