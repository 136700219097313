import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../../Store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { sortObjectArray } from "../../../utils";
import { Button, Container, Table } from "reactstrap";
import ListHeader from "./header";
import ListRow from "./row";
import Cards from "./Cards";
import { usePagination } from "../../Pagination/usePagination";
import { exportExcel } from "./exporter";
import Filters from "../../filter/Filters";
import Pagination from "../../Pagination/Pagination";
import Spinner from "../../../Spinner";
import { useApiCall } from "../../../useApiCall";

function List() {
    const [state] = useContext(Context);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(15);
    const [sortedField, setSortedField] = useState("");
    const [, setSortedDirection] = useState(null);
    const [transactions, setTransactions] = useState(null);
    const pagination = usePagination({
        currentPage,
        pageSize,
        records: transactions?.records,
    });
    const getTotalTransactionsPerPaymentMethod = useApiCall(
        "/total_transactions_per_payment_method"
    );

    useEffect(() => {
        const abort = new AbortController();

        setTransactions(null);
        getTotalTransactionsPerPaymentMethod(
            JSON.stringify(
                {
                    ...state.filters,
                    user: { userIds: state.userIds },
                    database: state.database,
                    posit: false,
                },
                "",
                { signal: abort.signal }
            )
        ).then((resp) => setTransactions(resp));

        return () => abort.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        setTransactions,
        state.filters,
        state.userIds,
        state.access_token,
        state.database,
    ]);

    const sort = (key, asc) => {
        if (key !== "" && asc !== null) {
            setSortedField(key);
            setSortedDirection(asc);
            setTransactions({
                ...transactions,
                records: sortObjectArray(transactions?.records, key, asc),
            });
        }
    };

    return (
        <Container fluid>
            <h2 className="page-header">
                Συνολικές συναλλαγές ανά τρόπο πληρωμής
            </h2>

            <Cards transactions={transactions} />

            <Filters
                statistics={transactions?.statistics}
                transactionsVisible={false}
                locationVisible={true}
                searchVisible={false}
            />

            {!transactions && <Spinner />}
            {transactions?.records?.length && (
                <>
                    <Table size="sm" striped hover responsive>
                        <ListHeader sortedField={sortedField} doSort={sort} />
                        <tbody>
                            {pagination.records?.map((row) => {
                                return (
                                    <ListRow
                                        key={row.originalIndex}
                                        data={row}
                                    />
                                );
                            })}
                        </tbody>
                    </Table>

                    <div className="exporters-container">
                        <Button
                            size="sm"
                            onClick={() =>
                                exportExcel(
                                    "TotalTransactionsPerPaymentMethod.xlsx",
                                    state,
                                    transactions?.statistics,
                                    transactions?.records
                                )
                            }
                        >
                            <FontAwesomeIcon icon="save" /> Εξαγωγή σε Excel
                        </Button>
                    </div>

                    <Pagination
                        pagination={pagination}
                        currentPage={currentPage}
                        pageSize={pageSize}
                        onPageChange={setCurrentPage}
                        setPageSize={setPageSize}
                    />
                </>
            )}
        </Container>
    );
}

export default List;
