import React from "react";

function Text({ firstRecord, lastRecord, totalRecords }) {
    return (
        <div className="pagination-block-text">
            <span className="pagination-text">Εγγραφές ανά σελίδα:</span>
            <span className="pagination-text">
                Εμφανίζονται οι εγγραφές {firstRecord}-{lastRecord} από{" "}
                {totalRecords}
            </span>
        </div>
    );
}

export default Text;
