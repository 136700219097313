import React from "react";
import NumberCard from "../../cards/NumberCard";

function Cards({ transactions }) {
  return (
    <div className="cards-container">
      <NumberCard
        icon="chart-pie"
        number={transactions?.records?.length || 0}
        title="Κατηγορίες"
      />
      <NumberCard
        icon="receipt"
        number={transactions?.statistics?.transactionsSum || 0}
        title="Συνολικές συναλλαγές"
      />
    </div>
  );
}

export default Cards;
