import { useContext } from "react";
import { Link } from "react-router-dom"
import { Col, Container, Row } from "reactstrap"
import { Context } from "../../Store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useApiDownload } from "../../useApiCall";
import "./downloads.css";

const Downloads = () => {
  const [state] = useContext(Context);
  const download = useApiDownload("/downloads", "GET")

  return (
    <Container fluid>
      <h2 className="page-header">Διάφορα έγγραφα</h2>

      {state?.downloads?.length === 0 &&
        <span className="text-muted">
          Μη διαθέσιμες
        </span>
      }

      {state?.downloads && <Row className="download-row" xs={1} sm={2} md={2} lg={3}>

        {Object.keys(state.downloads).map((k) => {
          return (
            <Col className="download-col" key={k}>
              <div className="download-category">{k}</div>
              {state.downloads[k].map((d) => {
                return (
                  <Link className="download-entry"
                    key={d.id}
                    onClick={(e) => {
                      e.preventDefault();
                      download(`/${d.id}`)
                        .then((resp) => resp.blob())
                        .then((blob) => {
                          const url = window.URL.createObjectURL(new Blob([blob]));
                          const link = document.createElement('a');
                          link.href = url;
                          link.setAttribute('download', d.name);
                          document.body.appendChild(link);
                          link.click();
                          link.parentNode.removeChild(link);
                        })
                    }}
                    target="_blank"
                    rel="noreferrer"
                    download>
                    <div className="sidebar-text">
                      <FontAwesomeIcon className="download-icon" icon="download" />
                      {" "}{d.displayName}
                    </div>
                  </Link>
                )
              })}
            </Col>
          )
        })}

      </Row>
      }
    </Container>
  )
}

export default Downloads;
