import React, { createContext, useReducer } from "react";
import Reducer from "./Reducer";
import { DefaultState } from "./State";

const Store = ({ children }) => {
    const [state, dispatch] = useReducer(Reducer, DefaultState());
    return (
        <Context.Provider value={[state, dispatch]}>
            {children}
        </Context.Provider>
    );
};

export const Context = createContext(DefaultState());
export default Store;
