import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Alert as BsAlert } from "reactstrap";
import "./alert.css";

function Alert({ color, isOpen, toggle, fade, timeout, onTimeout, children }) {
    useEffect(() => {
        if (timeout > 0) {
            const id = setTimeout(() => {
                if (toggle) {
                    toggle();
                }
                if (onTimeout) {
                    onTimeout();
                }
            }, timeout);
            return () => clearTimeout(id);
        }
    }, [toggle, onTimeout, timeout, isOpen]);

    return (
        <BsAlert color={color} isOpen={isOpen} toggle={toggle} fade={fade}>
            {children}
        </BsAlert>
    );
}

Alert.defaultProps = {
    color: "info",
    isOpen: false,
    toggle: null,
    fade: true,
    timeout: 0,
};

Alert.propTypes = {
    color: PropTypes.oneOf([
        "info",
        "primary",
        "secondary",
        "warning",
        "danger",
        "success",
        "default",
    ]),
    isOpen: PropTypes.bool,
    toggle: PropTypes.func,
    fade: PropTypes.bool,
    timeout: PropTypes.number,
};

export default Alert;
