import { useMemo } from "react";

export const DOTS = "...";

const range = (start, end) => {
    let length = end - start + 1;
    return Array.from({ length }, (_, idx) => idx + start);
};

export const usePagination = ({
    currentPage,
    pageSize,
    records,
    siblingCount = 1,
}) => {
    const paginationData = useMemo(() => {
        const totalCount = records?.length

        const thisPageStartIndex = (currentPage - 1) * pageSize;
        const thisPageEndIndex = thisPageStartIndex + pageSize;
        const thisPageFirstRecord = thisPageStartIndex + 1;
        const thisPageLastRecord = Math.min(thisPageEndIndex, totalCount);

        const paginated = records
            ?.slice(thisPageStartIndex, thisPageEndIndex)
            .map((item, index) => {
                return {
                    ...item,
                    originalIndex: thisPageStartIndex + index,
                };
            });

        let pages = [];

        const totalPageCount = Math.ceil(totalCount / pageSize);
        const totalPageNumbers = siblingCount + 5;

        if (totalPageNumbers >= totalPageCount) {
            pages = range(1, totalPageCount);
        } else {
            const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
            const rightSiblingIndex = Math.min(
                currentPage + siblingCount,
                totalPageCount
            );

            const shouldShowLeftDots = leftSiblingIndex > 2;
            const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

            const firstPageIndex = 1;
            const lastPageIndex = totalPageCount;

            if (!shouldShowLeftDots && shouldShowRightDots) {
                let leftItemCount = 3 + 2 * siblingCount;
                let leftRange = range(1, leftItemCount);
                pages = [...leftRange, DOTS, totalPageCount];
            }

            if (shouldShowLeftDots && !shouldShowRightDots) {
                let rightItemCount = 3 + 2 * siblingCount;
                let rightRange = range(
                    totalPageCount - rightItemCount + 1,
                    totalPageCount
                );
                pages = [firstPageIndex, DOTS, ...rightRange];
            }

            if (shouldShowLeftDots && shouldShowRightDots) {
                let middleRange = range(leftSiblingIndex, rightSiblingIndex);
                pages = [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
            }
        }

        return {
            startIndex: thisPageStartIndex,
            endIndex: thisPageEndIndex,
            startRecord: thisPageFirstRecord,
            endRecord: thisPageLastRecord,
            totalRecords: totalCount,
            records: paginated,
            pages: pages,
        };
    }, [currentPage, pageSize, records, siblingCount]);

    return paginationData;
};
