import React, { createRef, useContext, useEffect, useState } from "react";
import {
    Button,
    Container,
    Form,
    FormGroup,
    FormText,
    Input,
} from "reactstrap";
import { Context } from "../../Store";
import { useApiUpload } from "../../useApiCall";
import Alert from "../../Alert";

const TransactionsUpload = () => {
    const [uploadEnabled, setUploadEnabled] = useState(false);
    const [alertText, setAlertText] = useState({ text: "", color: "danger" });
    const [state] = useContext(Context);
    const filesRef = createRef();
    const apiUpload = useApiUpload("/transactions/upload");

    const submit = (e) => {
        e.preventDefault();

        const files = document.getElementById("file");
        if (files.files.length === 0) {
            console.log("No file selected");
            return;
        }

        const formData = new FormData();
        formData.append("file", files.files[0]);
        formData.append("database", state.database);

        apiUpload(formData).then((resp) => {
            // console.log(resp);
            if (resp.status) {
                setAlertText({ text: resp.status, color: "success" });
            } else if (resp.message) {
                setAlertText({ text: resp.message, color: "danger" });
            }
        });

        setUploadEnabled(false);
        files.value = "";
    };

    useEffect(() => {
        if (filesRef.current) {
            setUploadEnabled(
                filesRef.current.files.length > 0 && state.database.length > 0
            );
        }
    }, [setUploadEnabled, filesRef, state.database]);

    const fileSelected = (e) => {
        setUploadEnabled(
            e.target.files.length > 0 && state.database.length > 0
        );
    };

    const hideAlert = () => setAlertText({ text: "", color: "danger" });

    return (
        <Container fluid>
            {alertText.text.length > 0 && (
                <Alert
                    color={alertText.color}
                    isOpen={true}
                    toggle={hideAlert}
                    timeout={5000}
                >
                    <span className="mdi mdi-48px mdi-alert-octagon" />{" "}
                    {alertText.text}
                </Alert>
            )}
            <h2 className="page-header">Αποστολή αρχείου Excel</h2>
            <Container fluid style={{ maxWidth: 600 }}>
                <Form
                    method="POST"
                    encType="multipart/form-data"
                    onSubmit={submit}
                >
                    <FormGroup>
                        <Input
                            id="file"
                            name="file"
                            type="file"
                            innerRef={filesRef}
                            onChange={fileSelected}
                        />
                        <FormText>
                            <p>
                                Επιλέξτε ένα αρχείο Excel που περιέχει αναλυτικά
                                τις συναλλαγές της Εθνικής Τράπεζας.
                            </p>
                            <p>
                                Μόλις αποστείλετε το αρχείο, θα γίνει
                                επεξεργασία του από τον server και όλες οι
                                συναλλαγές που περιέχονται σ' αυτό θα εισαχθούν
                                στο σύστημα αυτομάτως.
                            </p>
                            <p>
                                Να σημειωθεί ότι ΔΕΝ υπάρχει πληροφόρηση, προς
                                το παρόν, για την πρόοδο/επιτυχία/αποτυχία της
                                διαδικασίας...
                            </p>
                        </FormText>
                    </FormGroup>
                    <FormGroup>
                        {state.database.length > 0 && (
                            <Button
                                color="primary"
                                size="sm"
                                disabled={!uploadEnabled}
                            >
                                Αποστολή προς {state.database.toUpperCase()}
                            </Button>
                        )}
                        {state.database.length === 0 && (
                            <FormText color="danger">
                                <p>
                                    Παρακαλώ επιλέξτε μία από τις διαθέσιμες
                                    βάσεις δεδομένων για να κάνετε την
                                    αποστολή&hellip;
                                </p>
                            </FormText>
                        )}
                    </FormGroup>
                </Form>
            </Container>
        </Container>
    );
};

export default TransactionsUpload;
