import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const ConfirmationDialog = ({
  isOpen = false,
  msg = "Message",
  title = "Επιβεβαίωση",
  buttons = ["Ναι", "Όχι"],
  colors = ["success", "danger"],
  closeFn = null,
}) => {
  return (
    <Modal isOpen={isOpen} toggle={closeFn} size="sm">
      <ModalHeader toggle={closeFn}>{title}</ModalHeader>
      <ModalBody>{msg}</ModalBody>
      <ModalFooter>
        {buttons?.map((b, index) => {
          let color = "secondary";
          if (index < colors?.length) {
            color = colors[index];
          }
          return (
            <Button key={index} color={color} onClick={() => closeFn(b)}>
              {b}
            </Button>
          );
        })}
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmationDialog;
