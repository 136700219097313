import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Table, UncontrolledTooltip } from "reactstrap";

function Row({ row, detailsFor, highlight, expandFn }) {
  const googleMapsQuery = "https://maps.google.com/maps?q=";

  const expandRow = () => {
    return (
      <td>
        <FontAwesomeIcon
          icon={
            row.database === detailsFor.database &&
            row.userId === detailsFor.userId
              ? "minus-square"
              : "plus-square"
          }
        />
      </td>
    );
  };

  const searchTermsFromAddress = (d) => {
    let all = [];
    if (d.address) all.push(d.address);
    if (d.area) all.push(d.area);
    if (d.city) all.push(d.city);
    if (d.nomos) all.push(d.nomos);
    if (d.zipCode) all.push(d.zipCode);
    let terms = all.join(" ").replaceAll(" ", "+");
    // console.log("map search:", terms);
    return terms;
  };

  const mapGotoUrl = (d) => {
    // z is the zoom level (1-20)
    // t is the map type ("m" map, "k" satellite, "h" hybrid, "p" terrain, "e" GoogleEarth)
    // q is the search query, if it is prefixed by loc: then google assumes it is a lat lon separated by a +

    const terms = searchTermsFromAddress(d);
    const url = `${googleMapsQuery}${terms}loc:${d.geoCoordinates.latitude},${d.geoCoordinates.longitude}`;
    // console.log("map goto:", url);
    return url;
  };

  const mapSearchUrl = (d) => {
    const terms = searchTermsFromAddress(d);
    const url = `${googleMapsQuery}${terms}`;
    // console.log("map search:", url);
    return url;
  };

  const rowid = `${row.database}-${row.userId}`;

  return (
    <>
      <tr
        className={
          "clickable " + (highlight ? "selected " : "") + "transaction-row"
        }
        id={rowid}
        key={rowid}
        onClick={() => expandFn(row)}
      >
        {expandRow()}
        <td>{row.database}</td>
        <td id={`company-${rowid}`}>
          {row.company}
          <UncontrolledTooltip target={`company-${rowid}`} placement="bottom">
            {row.phoneNumber && (
              <>
                <FontAwesomeIcon icon="phone-alt" /> {row.phoneNumber}
                <br />
              </>
            )}
            {row.email && (
              <>
                <FontAwesomeIcon icon="envelope" /> {row.email}
              </>
            )}
          </UncontrolledTooltip>
        </td>
        <td>{row.area}</td>
        <td>{row.city}</td>
        <td>{row.nomos}</td>
        <td>{row.zipCode}</td>
        <td>{row.tin}</td>
        <td align="right">{row.totalDevices}</td>
        <td align="right">{row.totalTransactions}</td>
        <td align="right">{row.commission.toFixed(2)}</td>
      </tr>
      {detailsFor?.records?.length !== 0 &&
        detailsFor.database === row.database &&
        detailsFor.userId === row.userId && (
          <tr
            className="transaction-row"
            key={`${row.database}-${row.userId}-detail`}
          >
            <td colSpan="2" />
            <td colSpan="9">
              <Table className="details" size="sm" striped>
                <thead>
                  <tr className="transaction-row">
                    <th className="contract">Σειριακός</th>
                    <th className="contract">Χάρτης</th>
                    <th>Διεύθυνση</th>
                    <th>Περιοχή</th>
                    <th>Πόλη</th>
                    <th>Νομός</th>
                    <th className="contract">Τ.Κ.</th>
                    <th className="contract rightAlign">Συναλλαγές</th>
                  </tr>
                </thead>
                <tbody>
                  {detailsFor?.records?.map((d) => {
                    return (
                      <tr className="transaction-row" key={d.deviceSerialNo}>
                        <td className="bold">{d.deviceSerialNo}</td>
                        <td>
                          {d.geoCoordinates.isValid && (
                            <a
                              href={mapGotoUrl(d)}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <FontAwesomeIcon icon="map" />
                              &nbsp;Μετάβαση
                            </a>
                          )}
                          {!d.geoCoordinates.isValid && (
                            <a
                              href={mapSearchUrl(d)}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <FontAwesomeIcon icon="search-location" />
                              &nbsp;Αναζήτηση
                            </a>
                          )}
                        </td>
                        <td>{d.address || "-"}</td>
                        <td>{d.area || "-"}</td>
                        <td>{d.city || "-"}</td>
                        <td>{d.nomos || "-"}</td>
                        <td>{d.zipCode || "-"}</td>
                        <td className="bold rightAlign">
                          {d.totalTransactions}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </td>
          </tr>
        )}
    </>
  );
}

export default Row;
