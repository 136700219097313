import React from "react";
import NumberCard from "../../cards/NumberCard";

function Cards({ transactions }) {
    return (
        <div className="cards-container">
            <NumberCard
                icon="receipt"
                number={transactions?.statistics?.transactionsSum || 0}
                title="Συναλλαγές"
            />
            <NumberCard
                icon="money-bill"
                number={(transactions?.statistics?.amountSum || 0).toLocaleString(undefined, {style: 'currency', currency: 'EUR'})}
                title="Ποσό"
            />
            <NumberCard
                icon="money-bill"
                number={(transactions?.statistics?.commissionSum || 0).toLocaleString(undefined, {style: 'currency', currency: 'EUR'})}
                title="Προμήθεια"
            />
        </div>
    );
}

export default Cards;
