import React from "react";
import NumberCard from "../../cards/NumberCard";

function Cards({ transactions }) {
    const percent = (v) => {
        const len = transactions?.records?.length || 0;
        if (len === 0) return "";
        const pct = v / len;
        if (isNaN(pct)) return "";
        return (pct * 100).toFixed(1) + "%";
    };

    return (
        <div className="cards-container">
            <NumberCard
                icon="building"
                number={transactions?.records?.length || 0}
                title="Έμποροι"
            />
            <NumberCard
                icon="receipt"
                number={transactions?.statistics?.transactionsSum || 0}
                title="Συναλλαγές"
            />
            <NumberCard
                icon="money-bill"
                number={transactions?.statistics?.buckets?.upToTen || 0}
                title="Έμποροι με <=10 συναλλαγές"
                subtitle={percent(transactions?.statistics?.buckets?.upToTen)}
            />
            <NumberCard
                icon="money-bill-wave"
                number={transactions?.statistics?.buckets?.other || 0}
                title="Έμποροι με >100 συναλλαγές"
                subtitle={percent(transactions?.statistics?.buckets?.other)}
            />
            <NumberCard
                icon="money-bill"
                number={(transactions?.statistics?.commissionSum || 0).toFixed(
                    1
                )}
                title="Σύνολο προμηθειών"
            />
        </div>
    );
}

export default Cards;
