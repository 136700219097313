import { createContext, useContext } from "react";
import { useLocation, Navigate, Link } from "react-router-dom";
import { Context } from "./Store";
import { LOGIN, LOGOUT } from "./Reducer";
import { config } from "./Config";
import { Button, Container } from "reactstrap";

export const AuthContext = createContext({ signin: null, signout: null });

export const AuthProvider = ({ children }) => {
    const [state, dispatch] = useContext(Context);

    const signin = (access_token, callback = undefined) => {
        dispatch({
            type: LOGIN,
            payload: {
                access_token: `${access_token}`,
            },
        });
        if (callback) callback();
    };

    const signout = (callback) => {
        let headers = {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: `Bearer ${state.access_token}`,
        };
        fetch(config.API_URL + "/logout", {
            mode: "cors",
            credentials: "include",
            method: "POST",
            headers: headers,
            body: JSON.stringify({ access_token: state.access_token }),
        }).then(() => {
            setTimeout(() => dispatch({ type: LOGOUT }), 250);
            if (callback) callback();
        });
    };

    const is_signed_in = () =>
        state.access_token !== undefined && state.access_token !== null;

    const v = { signin, signout, is_signed_in };
    return <AuthContext.Provider value={v}>{children}</AuthContext.Provider>;
};

export const RequireAuth = ({ children }) => {
    const [state] = useContext(Context);
    const loc = useLocation();

    if (!state.access_token) {
        return <Navigate to="/login" state={{ from: loc }} replace />;
    }

    return children;
};

export const RequireAdmin = ({ children }) => {
    const [state] = useContext(Context);

    if (!state.is_admin) {
        return (
            <Container fluid>
                <h3>Ελλειπή δικαιώματα</h3>
                <p>
                    Δυστυχώς δεν έχετε τα απαραίτητα δικαιώματα για πρόσβαση σε
                    αυτή την σελίδα...
                </p>
                <Button color="secondary" size="sm">
                    <Link to="/">Επιστροφή στην αρχική</Link>
                </Button>
            </Container>
        );
    }

    return children;
};
