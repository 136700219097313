import React, { useContext, useState } from "react";
import { Context } from "../../../Store";
import SortableLink from "../../SortableLink";
import { Input } from "reactstrap";

function Header({ sortedField, doSort, onChanged, devices, statuses }) {
    const [state] = useContext(Context);
    const [category, setCategory] = useState(undefined)
    const [method, setMethod] = useState(undefined)
    const [status, setStatus] = useState(undefined)
    const [device, setDevice] = useState(undefined)
    const [clrRef, setClrRef] = useState("")
    const [org, setOrg] = useState("")

    const allStatuses = [undefined].concat(Object.keys(statuses))
    const allDevices = [undefined].concat(devices)
    const categories = [undefined].concat(state.availableCategories)
    const methodsTranslated = [undefined, "Μετρητά", "Κάρτα"]
    const methods = [undefined, "CASH", "CARD"]

    return (
        <thead>
            <tr>
                <th>
                    <SortableLink
                        title="Ημερομηνία"
                        sortField="stamp"
                        sortedField={sortedField}
                        doSort={doSort}
                    />
                </th>
                <th>
                    <SortableLink
                        title="Κατηγορία"
                        sortField="category"
                        sortedField={sortedField}
                        doSort={doSort}
                    />
                </th>
                <th>
                    <SortableLink
                        title="Οργανισμός"
                        sortField="organization"
                        sortedField={sortedField}
                        doSort={doSort}
                    />
                </th>
                <th>
                    <SortableLink
                        title="Συσκευή"
                        sortField="serialNo"
                        sortedField={sortedField}
                        doSort={doSort}
                    />
                </th>
                <th>
                    <SortableLink
                        title="Μέθοδος"
                        sortField="method"
                        sortedField={sortedField}
                        doSort={doSort}
                    />
                </th>
                <th>
                    <SortableLink
                        title="Κατάσταση"
                        sortField="status"
                        sortedField={sortedField}
                        doSort={doSort}
                    />
                </th>
                <th>
                    <SortableLink
                        title="Κωδικός συναλλαγής"
                        sortField="clearingRef"
                        sortedField={sortedField}
                        doSort={doSort}
                    />
                </th>
                <th align="right">
                    <SortableLink
                        title="Ποσό"
                        sortField="amount"
                        sortedField={sortedField}
                        doSort={doSort}
                    />
                </th>
                <th align="right">
                    <SortableLink
                        title="Προμήθεια"
                        sortField="commission"
                        sortedField={sortedField}
                        doSort={doSort}
                    />
                </th>
            </tr>
            <tr>
                <th/>
                <th>
                  <Input
                    type="select"
                    name="category"
                    id="category"
                    bsSize="sm"
                    value={category}
                    onChange={(e) => {
                        setCategory(e.target.value)
                        if (onChanged)
                            onChanged(
                              state.availableCategories[e.target.value-1],
                              org,
                              methods[method],
                              allStatuses[status],
                              allDevices[device],
                              e.target.value,
                            )
                        }
                    }
                  >
                    {categories?.map((text, index) => (
                      <option key={index} value={index}>
                        {text}
                      </option>
                    ))}
                  </Input>
                </th>
                <th>
                  <Input
                    type="text"
                    name="org"
                    id="org"
                    bsSize="sm"
                    value={org}
                    onChange={(e) => {
                      setOrg(e.target.value)
                      if (onChanged) {
                          onChanged(
                            state.availableCategories[category-1],
                            e.target.value,
                            methods[method],
                            allStatuses[status],
                            allDevices[device],
                            clrRef,
                          )
                        }
                    }}
                  />
                </th>
                <th>
                  <Input
                    type="select"
                    name="device"
                    id="device"
                    bsSize="sm"
                    value={device}
                    onChange={(e) => {
                        setDevice(e.target.value)
                        if (onChanged)
                            onChanged(
                              state.availableCategories[category-1],
                              org,
                              methods[method],
                              allStatuses[status],
                              allDevices[e.target.value],
                              clrRef,
                            )
                        }
                    }
                  >
                    {allDevices?.map((text, index) => (
                      <option key={index} value={index}>
                        {text}
                      </option>
                    ))}
                  </Input>
                </th>
                <th>
                  <Input
                    type="select"
                    name="method"
                    id="method"
                    bsSize="sm"
                    value={method}
                    onChange={(e) => {
                        setMethod(e.target.value)
                        if (onChanged)
                            onChanged(
                              state.availableCategories[category-1],
                              org,
                              methods[e.target.value],
                              allStatuses[status],
                              allDevices[device],
                              clrRef,
                            )
                        }
                    }
                  >
                    {methodsTranslated?.map((text, index) => (
                      <option key={index} value={index}>
                        {text}
                      </option>
                    ))}
                  </Input>
                </th>
                <th>
                  <Input
                    type="select"
                    name="status"
                    id="status"
                    bsSize="sm"
                    value={status}
                    onChange={(e) => {
                        setStatus(e.target.value)
                        if (onChanged)
                            onChanged(
                              state.availableCategories[category-1],
                              org,
                              methods[method],
                              allStatuses[e.target.value],
                              allDevices[device],
                              clrRef,
                            )
                        }
                    }
                  >
                    {allStatuses?.map((text, index) => (
                      <option key={index} value={index}>
                        {text}
                      </option>
                    ))}
                  </Input>
                </th>
                <th>
                  <Input
                    type="text"
                    name="clrRef"
                    id="clrRef"
                    bsSize="sm"
                    value={clrRef}
                    onChange={(e) => {
                      setClrRef(e.target.value)
                      if (onChanged) {
                          onChanged(
                            state.availableCategories[category-1],
                            org,
                            methods[method],
                            allStatuses[status],
                            allDevices[device],
                            e.target.value,
                          )
                        }
                    }}
                  />
                </th>
                <th/>
                <th/>
            </tr>
        </thead>
    );
}

export default Header;
