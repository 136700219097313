import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faArrowLeft,
    faArrowRight,
    faBars,
    faBatteryEmpty,
    faBroom,
    faBuilding,
    faCashRegister,
    faChartPie,
    faCheck,
    faCloud,
    faCreditCard,
    faCog,
    faDatabase,
    faDiagnoses,
    faDoorOpen,
    faDownload,
    faEdit,
    faEnvelope,
    faEuroSign,
    faExclamationTriangle,
    faEye,
    faFilter,
    faGlobe,
    faHandHoldingUsd,
    faMap,
    faMinusSquare,
    faMoneyBill,
    faMoneyBillWave,
    faPencilAlt,
    faPhone,
    faPhoneAlt,
    faPiggyBank,
    faPlusSquare,
    faPrint,
    faReceipt,
    faSave,
    faSearch,
    faSearchLocation,
    faSimCard,
    faSitemap,
    faSort,
    faSortDown,
    faSortUp,
    faSpinner,
    faSyncAlt,
    faThumbsUp,
    faTimes,
    faTrashAlt,
    faUpload,
    faUser,
    faUsersCog,
    faUserEdit,
    faUserShield,
} from "@fortawesome/free-solid-svg-icons";

library.add(
    faArrowLeft,
    faArrowRight,
    faBars,
    faBatteryEmpty,
    faBroom,
    faBuilding,
    faCashRegister,
    faChartPie,
    faCheck,
    faCloud,
    faCreditCard,
    faCog,
    faDatabase,
    faDiagnoses,
    faDoorOpen,
    faDownload,
    faEdit,
    faEnvelope,
    faEuroSign,
    faExclamationTriangle,
    faEye,
    faFilter,
    faGlobe,
    faHandHoldingUsd,
    faMap,
    faMinusSquare,
    faMoneyBill,
    faMoneyBillWave,
    faPencilAlt,
    faPhone,
    faPhoneAlt,
    faPiggyBank,
    faPlusSquare,
    faPrint,
    faReceipt,
    faSave,
    faSearch,
    faSearchLocation,
    faSimCard,
    faSitemap,
    faSort,
    faSortDown,
    faSortUp,
    faSpinner,
    faSyncAlt,
    faThumbsUp,
    faTimes,
    faTrashAlt,
    faUpload,
    faUser,
    faUsersCog,
    faUserEdit,
    faUserShield
);
