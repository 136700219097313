import { DefaultFilters } from "./State";
import jwt_decode from "jwt-decode";

let _internal_alert_counter = 1;

export const SET_PAGINATION_PAGE_SIZE = "SET_PAGINATION_PAGE_SIZE";
export const SET_DISPLAY_MODE = "SET_DISPLAY_MODE";
export const SET_PRESET_DATES = "SET_PRESET_DATES";
export const SET_LIMITS = "SET_LIMITS";
export const SET_AVAILABLE_BANKS = "SET_AVAILABLE_BANKS";
export const SET_AVAILABLE_DBS = "SET_AVAILABLE_DBS";
export const SET_AVAILABLE_CATEGORIES = "SET_AVAILABLE_CATEGORIES";
export const SET_SALESMEN = "SET_SALESMEN";
export const SET_AREAS = "SET_AREAS";
export const SET_CITIES = "SET_CITIES";
export const SET_NOMOI = "SET_NOMOI";
export const SET_ZIPCODES = "SET_ZIPCODES";
export const SET_BANK = "SET_BANK";
export const SET_DATABASE = "SET_DATABASE";
export const SET_FLT_ALL = "SET_FLT_ALL";
export const SET_FLT_LOC = "SET_FLT_LOC";
export const SET_FLT_TRANS = "SET_FLT_TRANS";
export const SET_FLT_DATE = "SET_FLT_DATE";
export const SET_FLT_LOC_AND_SEARCH_TEXT = "SET_FLT_LOC_AND_SEARCH_TEXT";
export const SET_DOWNLOADS = "SET_DOWNLOADS";
export const ADD_USER_ID = "ADD_USER_ID";
export const REMOVE_USER_ID = "REMOVE_USER_ID";
export const CLEAR_USER_IDS = "CLEAR_USER_IDS";
export const SET_SEARCH_TEXT = "SET_SEARCH_TEXT";
export const ADD_ALERT = "ADD_ALERT";
export const DELETE_ALERT = "DELETE_ALERT";
export const SET_ERROR = "SET_ERROR";
export const RESET_FILTERS = "RESET_FILTERS";
export const LOGIN = "LOGIN";
export const REFRESH_TOKENS = "REFRESH_TOKENS";
export const LOGOUT = "LOGOUT";

const calculateDateFilter = (dateFilter, state) => {
    const options = { weekday: "numeric", year: "numeric", month: "long" };
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    let curStart = dateFilter.custom.start;
    let curEnd = dateFilter.custom.end;

    if (curStart === null) {
        curStart = yesterday.toLocaleDateString("en-US", options);
    }
    if (curEnd === null) {
        curStart = yesterday.toLocaleDateString("en-US", options);
    }

    if (dateFilter.range !== 0) {
        curStart = state.presetDates[dateFilter.range].start;
        curEnd = state.presetDates[dateFilter.range].end;
    }

    return {
        range: dateFilter.range,
        custom: {
            start: curStart,
            end: curEnd,
        },
    };
};

const Reducer = (state, action) => {
    let user = null;
    let db = "";
    let bank = "";
    let is_admin = false;
    let is_dealer = false;

    switch (action.type) {
        case SET_PAGINATION_PAGE_SIZE:
            sessionStorage.setItem("page_size", action.payload);
            return {
                ...state,
                pageSize: action.payload,
            }
        case SET_DISPLAY_MODE:
            return {
                ...state,
                displayMode: action.payload,
            };
        case SET_PRESET_DATES:
            return {
                ...state,
                presetDates: action.payload,
            };
        case SET_LIMITS:
            return {
                ...state,
                limits: action.payload,
            };
        case SET_SALESMEN:
            return {
                ...state,
                salesmen: action.payload,
            };
        case SET_AREAS:
            return {
                ...state,
                areas: action.payload,
            };
        case SET_CITIES:
            return {
                ...state,
                cities: action.payload,
            };
        case SET_NOMOI:
            return {
                ...state,
                nomoi: action.payload,
            };
        case SET_ZIPCODES:
            return {
                ...state,
                zipcodes: action.payload,
            };
        case SET_AVAILABLE_BANKS:
            // set first db as user db, if not an admin and no db set
            bank = state.bank;
            if (!state.is_admin && action.payload?.length) {
                if (!bank) {
                    bank = action.payload[0];
                } else {
                    // also check the case where logout/login sequence
                    // from admin to non-admin so db is no longer valid for
                    // current user
                    if (action.payload.indexOf(bank) === -1) {
                        bank = action.payload[0];
                    }
                }
            }
            return {
                ...state,
                availableBanks: action.payload,
                bank: bank,
            };
        case SET_AVAILABLE_DBS:
            // set first db as user db, if not an admin and no db set
            db = state.database;
            if (!state.is_admin && action.payload?.length) {
                if (!db) {
                    db = action.payload[0];
                } else {
                    // also check the case where logout/login sequence
                    // from admin to non-admin so db is no longer valid for
                    // current user
                    if (action.payload.indexOf(db) === -1) {
                        db = action.payload[0];
                    }
                }
            }
            return {
                ...state,
                availableDatabases: action.payload,
                database: db,
            };
        case SET_AVAILABLE_CATEGORIES:
            return {
                ...state,
                availableCategories: action.payload,
            }
        case SET_BANK:
            bank = action.payload;
            sessionStorage.setItem("bank", bank);
            return {
                ...state,
                bank: bank,
            };
        case SET_DATABASE:
            // set first db as user db, if not an admin and no db set
            db = action.payload;
            if (!state.is_admin && state.availableDatabases?.length) {
                if (!db) {
                    db = state.availableDatabases[0];
                } else {
                    // also check the case where logout/login sequence
                    // from admin to non-admin so db is no longer valid for
                    // current user
                    if (state.availableDatabases.indexOf(db) === -1) {
                        db = state.availableDatabases[0];
                    }
                }
            }
            sessionStorage.setItem("database", db);
            return {
                ...state,
                database: db,
            };
        case SET_FLT_ALL:
            // console.log("SET_FLT_ALL");
            return {
                ...state,
                filters: action.payload.filters,
                searchText: action.payload.searchText,
            };
        case SET_FLT_LOC:
            // console.log("SET_FLT_LOC");
            return {
                ...state,
                filters: {
                    ...state.filters,
                    location: action.payload,
                },
            };
        case SET_FLT_TRANS:
            // console.log("SET_FLT_TRANS");
            return {
                ...state,
                filters: {
                    ...state.filters,
                    transactions: action.payload,
                },
            };
        case SET_FLT_DATE:
            // console.log("SET_FLT_DATE");
            return {
                ...state,
                filters: {
                    ...state.filters,
                    date: calculateDateFilter(action.payload, state),
                },
            };
        case RESET_FILTERS:
            // console.log("RESET_FILTERS");
            return {
                ...state,
                filters: DefaultFilters(),
                searchText: "",
            };
        case SET_FLT_LOC_AND_SEARCH_TEXT:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    location: action.payload.location,
                },
                searchText: action.payload.searchText,
            };
        case SET_SEARCH_TEXT:
            return {
                ...state,
                searchText: action.payload,
            };
        case SET_DOWNLOADS:
            return {
                ...state,
                downloads: action.payload,
            };
        case ADD_USER_ID:
            const result = [...state.userIds, action.payload];
            sessionStorage.setItem("userids", JSON.stringify(result));
            return {
                ...state,
                userIds: result,
            };
        case REMOVE_USER_ID:
            const newUserIds = state.userIds;
            const existing = newUserIds.indexOf(action.payload);
            if (existing !== -1) {
                newUserIds.splice(existing, 1);
            }
            sessionStorage.setItem("userids", JSON.stringify(newUserIds));
            return {
                ...state,
                userIds: newUserIds,
            };
        case CLEAR_USER_IDS:
            sessionStorage.setItem("userids", "[]");
            return {
                ...state,
                userIds: [],
            };
        case ADD_ALERT:
            if (!action.payload.title && !action.payload.message) {
                console.log("ignoring alert, no title or message");
                return state;
            }
            if (
                action.payload.timeout === null ||
                action.payload.timeout === undefined
            ) {
                action.payload.timeout = 5000;
            }
            return {
                ...state,
                alerts: [
                    ...state.alerts,
                    {
                        id: action.payload.id || `${_internal_alert_counter++}`,
                        title: action.payload.title || "",
                        message: action.payload.message || "",
                        color: action.payload.color || "info",
                        timeout: action.payload.timeout,
                        _startTime: Date.now(), // ms since 1/1/1970
                    },
                ],
            };
        case DELETE_ALERT:
            return {
                ...state,
                alerts: state.alerts.filter((a) => a.id !== action.payload),
            };
        case SET_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        case LOGIN:
            if (action.payload.access_token) {
                sessionStorage.setItem(
                    "access_token",
                    action.payload.access_token
                );
                const decoded = jwt_decode(action.payload.access_token);
                user = decoded?.name;
                is_admin = decoded?.admin;
                is_dealer = decoded?.dealer;
            } else {
                sessionStorage.removeItem("access_token");
                user = null;
                is_admin = false;
                is_dealer = false;
            }
            return {
                ...state,
                access_token: action.payload.access_token,
                user: user,
                is_admin: is_admin,
                is_dealer: is_dealer,
            };
        case REFRESH_TOKENS:
            if (action.payload.access_token) {
                sessionStorage.setItem(
                    "access_token",
                    action.payload.access_token
                );
                const decoded = jwt_decode(action.payload.access_token);
                user = decoded?.name;
                is_admin = decoded?.admin;
                is_dealer = decoded?.dealer;
            } else {
                sessionStorage.removeItem("access_token");
                user = null;
                is_admin = false;
                is_dealer = false;
            }
            return {
                ...state,
                access_token: action.payload.access_token,
                user: user,
                is_admin: is_admin,
                is_dealer: is_dealer,
            };
        case LOGOUT:
            sessionStorage.removeItem("access_token");
            return {
                ...state,
                access_token: null,
                user: null,
                is_admin: false,
                is_dealer: false,
                error: "Αποσυνδεθήκατε, παρακαλώ συνδεθείτε ξανά...",
            };
        default:
            return state;
    }
};

export default Reducer;
