import React, { useContext, useEffect, useState } from "react";
import { Link, Navigate, useLocation } from "react-router-dom";
import {
  Badge,
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  UncontrolledDropdown,
} from "reactstrap";
import {
  ADD_USER_ID,
  CLEAR_USER_IDS,
  SET_BANK,
  SET_DATABASE,
} from "../Reducer";
import { Context } from "../Store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./pagenav.css";
import { useWebsockets } from "../useWebsockets";
import { TriliaAppMode } from "../Config";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { useAnnouncements } from "../useAnnouncements";

function PageNav() {
  const [state] = useContext(Context);
  const [collapsed, setCollapsed] = useState(false);

  const toggle = () => setCollapsed(!collapsed);

  return (
    <Navbar dark expand="md">
      <NavbarBrand href="/dashboard">
        <div className="logo">
          <img
            src="/assets/Trilia_Logo_White.png"
            alt="logo"
          />
        </div>
      </NavbarBrand>
      <NavbarToggler className="me-2" onClick={toggle} />
      <Collapse isOpen={collapsed} navbar>
        <NavbarMenu state={state} />
        <NavbarMiddle state={state} />
        <NavbarRight state={state} />
      </Collapse>
    </Navbar>
  );
}

const NavbarMenu = () => {
  return <></>;
  // return state.access_token && (
  //   <Nav className="navbar-menu" navbar>
  //     {SiteMenu.map((m) => {
  //       return <Fragment key={m.name}>
  //         {m.entries.map((i) => {
  //           return <NavItem key={m.name + i.name}>
  //             <Link to={i.path}>
  //               { m.name + ": " + i.name }
  //             </Link>
  //           </NavItem>
  //         })
  //         }
  //       </Fragment>
  //     })}
  //   </Nav>
  // );
};

const NavbarMiddle = ({ state }) => {
  const pathname = useLocation().pathname;
  return (
    <Nav className="mx-auto" navbar>
      {state.access_token && !TriliaAppMode && (
        <>
          <Databases />
          <Banks />
          {!pathname.startsWith("/dashboard") &&
            <Salesmen />}
        </>
      )}
    </Nav>
  );
};

const NavbarRight = ({ state }) => {
  return (
    <Nav navbar>
      {!TriliaAppMode && state.is_admin && <Advanced state={state} />}
      {state.access_token && <User state={state} />}
    </Nav>
  );
};

const Advanced = () => {
  return (
    <UncontrolledDropdown nav inNavbar>
      <DropdownToggle nav caret>
        <FontAwesomeIcon icon="cog" />{" "}
        <span className="nav-text">Πρόσθετα</span>
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem header>Διαχείριση</DropdownItem>
        <DropdownItem>
          <Link to="/settings/salesmen">
            <FontAwesomeIcon icon="user-edit" /> Διαχείριση πωλητών
          </Link>
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem>
          <Link to="/tr/upload">
            <FontAwesomeIcon icon="upload" /> Φόρτωμα συναλλαγών
          </Link>
        </DropdownItem>
        <DropdownItem>
          <Link to="/settings/errors">
            <FontAwesomeIcon icon="diagnoses" /> Προβλήματα
          </Link>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

const User = ({ state }) => {
  const [announcements, unread] = useAnnouncements();

  // <DropdownItem>
  //   <Link to="/contact">
  //     <FontAwesomeIcon icon="envelope" /> Επικοινωνήστε μαζί μας
  //   </Link>
  // </DropdownItem>
  return (
    <UncontrolledDropdown nav inNavbar>
      <DropdownToggle nav caret>
        {unread > 0 && (
          <Badge color="success">
            {unread}
            {unread === 1 ? " νέα ανακοίνωση" : " νέες ανακοινώσεις"}
          </Badge>
        )}{" "}
        <FontAwesomeIcon
          icon={state.is_admin ? "user-shield" : "user"}
        />{" "}
        <span className="nav-text">{state.user}</span>
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem>
          <Link to="/announcements">
            <FontAwesomeIcon icon={faEnvelope} /> Ανακοινώσεις{" "}
            <Badge color={unread > 0 ? "success" : "info"}>
              {unread}/{announcements?.length}
            </Badge>
          </Link>
        </DropdownItem>
        <DropdownItem>
          <Link to="/user">
            <FontAwesomeIcon icon="user" /> Προτιμήσεις
          </Link>
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem>
          <Link to="/logout">
            <FontAwesomeIcon icon="door-open" /> Αποσύνδεση
          </Link>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

const Databases = () => {
  const [state, dispatch] = useContext(Context);
  const setDatabase = (db) => {
    dispatch({ type: SET_DATABASE, payload: db });
  };

  return (
    <div className="navbar-source-list">
      <Label for="db">
        <FontAwesomeIcon icon="database" />
      </Label>
      <Input
        id="db"
        type="select"
        bsSize="sm"
        readOnly
        value={state.database}
        onChange={(e) => setDatabase(e.target.value)}
      >
        {state.is_admin && (
          <option key="seldball" value="">
            Όλες οι πηγές
          </option>
        )}
        {state.availableDatabases?.map &&
          state.availableDatabases.map((v) => {
            return (
              <option key={v} value={v}>
                {v.toUpperCase()}
              </option>
            );
          })}
      </Input>
    </div>
  );
};

const Banks = () => {
  const [state, dispatch] = useContext(Context);
  const setBank = (bank) => {
    dispatch({ type: SET_BANK, payload: bank });
  };

  return (
    <div className="navbar-source-list">
      <Label for="bank">
        <FontAwesomeIcon icon="money-bill" />
      </Label>
      <Input
        id="bank"
        type="select"
        bsSize="sm"
        readOnly
        value={state.bank}
        onChange={(e) => setBank(e.target.value)}
      >
        <option key="selbankall" value="">
          Όλες οι τράπεζες
        </option>
        {state.availableBanks?.map &&
          state.availableBanks.map((v) => {
            return (
              <option key={v} value={v}>
                {v.toUpperCase()}
              </option>
            );
          })}
      </Input>
    </div>
  );
};

const Salesmen = () => {
  const [state, dispatch] = useContext(Context);
  const [allSmen, setSmen] = useState([]);

  const [connected, send] = useWebsockets();

  useEffect(
    () => {
      if (connected) {
        send("salesmen", null, (msg) => setSmen(msg.payload || []));
      }
    },
    [connected, send, state.access_token],
  );

  //   const selectAll = () => {
  //     allSmen?.map((v) => {
  //       return dispatch({ type: ADD_USER_ID, payload: v.id });
  //     });
  //   };

  const selectNone = () => {
    dispatch({ type: CLEAR_USER_IDS });
  };

  const selectSman = (uuid) => {
    dispatch({ type: CLEAR_USER_IDS });
    dispatch({
      type: ADD_USER_ID,
      payload: uuid,
    });
  };

  if (!state.is_admin || allSmen?.length === 0) {
    return <></>;
  }

  return (
    <div className="navbar-source-list">
      <Label for="sman">
        <FontAwesomeIcon icon="pencil-alt" />
      </Label>
      <Input
        id="sman"
        type="select"
        bsSize="sm"
        readOnly
        value={state?.userIds.length > 0 ? state.userIds[0] : ""}
        onChange={(e) => {
          const v = e.target.value;
          if (v === "") {
            selectNone();
          } else {
            selectSman(v);
          }
        }}
      >
        <option key="selnone" value="">
          Χωρίς φίλτρο πωλητή
        </option>
        {/* <option */}
        {/*   key="selall" */}
        {/*   value={allSmen */}
        {/*     ?.map((v) => { */}
        {/*       return v.id; */}
        {/*     }) */}
        {/*     .join(",")} */}
        {/*   onClick={selectAll} */}
        {/* > */}
        {/*   Όλοι οι πωλητές */}
        {/* </option> */}
        {allSmen &&
          allSmen.map((v) => {
            return (
              <option key={`${v.database}.${v.id}`} value={v.id}>
                {v.fullName}
              </option>
            );
          })}
      </Input>
    </div>
  );
};

export default PageNav;
