import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Table, UncontrolledTooltip } from "reactstrap";

function Row({ data, expandFn, isExpanded, highlight }) {
  const expandRowIcon = () => {
    return (
      <td>
        <FontAwesomeIcon icon={isExpanded ? "minus-square" : "plus-square"} />
      </td>
    );
  };

  return (
    <>
      <tr
        className={
          "clickable " + (highlight ? "selected " : "") + "transaction-row"
        }
        key={data.dealerId}
        onClick={() => expandFn(isExpanded ? "" : data.dealerId)}
      >
        {expandRowIcon()}
        <td id={`dealer-${data.dealerId}`}>
          {data.company}
          <UncontrolledTooltip
            target={`dealer-${data.dealerId}`}
            placement="bottom"
          >
            {data?.phoneNumber && (
              <>
                <FontAwesomeIcon icon="phone-alt" /> {data.phoneNumber}
                <br />
              </>
            )}
            {data?.email && (
              <>
                <FontAwesomeIcon icon="envelope" /> {data.email}
              </>
            )}
          </UncontrolledTooltip>
        </td>
        <td>{data?.tin}</td>
        <td align="right">{data.totalDevices}</td>
        <td align="right">{data.totalTransactions}</td>
        <td align="right">{data.commission.toFixed(2)}</td>
      </tr>
      {isExpanded && data.devices.length !== 0 && (
        <tr className="transaction-row" key={data.dealerId + "-detail"}>
          <td colSpan="7">
            <Table className="details-lg" size="sm" bordered striped>
              <thead>
                <tr className="transaction-row">
                  <th>Εταιρία</th>
                  <th>ΑΦΜ</th>
                  <th>Σειριακός</th>
                  <th style={{ textAlign: "right" }}>Συναλλαγές</th>
                  <th style={{ textAlign: "right" }}>Προμήθεια</th>
                </tr>
              </thead>
              <tbody>
                {data.devices.map((d) => (
                  <tr className="transaction-row" key={d.deviceSerialNo}>
                    <td id={`device-${d.deviceSerialNo}`}>
                      {d.company}
                      <UncontrolledTooltip
                        target={`device-${d.deviceSerialNo}`}
                        placement="bottom"
                      >
                        {d?.phoneNumber && (
                          <>
                            <FontAwesomeIcon icon="phone-alt" /> {d.phoneNumber}
                            <br />
                          </>
                        )}
                        {d?.email && (
                          <>
                            <FontAwesomeIcon icon="envelope" /> {d.email}
                          </>
                        )}
                      </UncontrolledTooltip>
                    </td>
                    <td>{d.tin}</td>
                    <td>{d.deviceSerialNo}</td>
                    <td style={{ textAlign: "right" }}>
                      {d.totalTransactions}
                    </td>
                    <td style={{ textAlign: "right" }}>
                      {d.commission.toFixed(2)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </td>
        </tr>
      )}
    </>
  );
}

export default Row;
