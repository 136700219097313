import { Button, Container, Table } from "reactstrap";
import { useAnnouncements } from "../useAnnouncements";
import { useState } from "react";
import "./announcements.css";

const Announcements = () => {
  const [announcements, _, markRead, markUnread] = useAnnouncements();
  const [selected, setSelected] = useState(null);

  const toggleRead = (announcement) => {
    console.log(announcement);
    if (!announcement) {
      return;
    }
    if (announcement.read) {
      markUnread(announcement.publishing.id);
    }
    if (!announcement.read) {
      markRead(announcement.publishing.id);
    }
    setSelected(null);
  };

  return (
    <Container fluid>
      <h2 className="page-header">Μηνύματα / Ανακοινώσεις</h2>

      {announcements.length === 0 && (
        <span className="text-muted">Δεν υπάρχουν</span>
      )}

      {selected && (
        <Announcement
          announcement={selected}
          back={() => setSelected(null)}
          toggleRead={() => toggleRead(selected)}
        />
      )}
      {!selected && (
        <TableList
          announcements={announcements}
          selectItem={(item) => setSelected(item)}
        />
      )}
    </Container>
  );
};

const Announcement = ({ announcement, back, toggleRead }) => {
  const d = new Date(announcement.publishing.date).toLocaleDateString("el-GR");
  return (
    <div className="announcement">
      <div className="title">
        {announcement.title}
      </div>
      <div className="author">
        από {announcement.publishing.author} την {d}
      </div>
      <p className="message">{announcement.message}</p>
      <div className="buttons">
        <Button color="primary" size="sm" onClick={toggleRead}>
          Μαρκάρισμα ως {announcement.read ? "μη" : ""} αναγνωσμένο
        </Button>
        <Button size="sm" onClick={back}>Επιστροφή στα μηνύματα</Button>
      </div>
    </div>
  );
};

const TableList = ({ announcements, selectItem }) => {
  if (!announcements?.length) {
    return <></>;
  }

  return (
    <Table size="sm" striped hover responsive>
      <TableHeader />
      <tbody>
        {announcements.map((item) => {
          return (
            <TableRow
              row={item}
              key={item.publishing.id}
              selectItem={selectItem}
            />
          );
        })}
      </tbody>
    </Table>
  );
};

const TableHeader = () => {
  return (
    <thead>
      <tr>
        <th className="cell-shrink">Ημερομηνία</th>
        <th className="cell-shrink">Αποστολέας</th>
        <th>Θέμα</th>
      </tr>
    </thead>
  );
};

const TableRow = ({ row, selectItem }) => {
  return (
    <tr
      className={`announcement-${!row.read ? "un" : ""}read`}
      id={row.publishing.id}
      key={row.publishing.id}
      onClick={() => selectItem(row)}
    >
      <td data-cell="Ημερομηνία">
        {new Date(row.publishing.date).toLocaleString("el-GR", {
          dateStyle: "short",
          timeStyle: "medium",
          hour12: false,
        })}
      </td>
      <td data-cell="Αποστολέας">{row.publishing.author}</td>
      <td data-cell="Θέμα">{row.title}</td>
    </tr>
  );
};

export default Announcements;
