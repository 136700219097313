import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function SortableLink({ title, sortField, sortedField, doSort }) {
  const [sorting, setSorting] = useState({
    asc: undefined,
    icon: "sort",
  });

  useEffect(() => {
    let tmp = sorting;
    if (sortField !== sortedField) {
      tmp = { asc: true, icon: "sort" };
    } else {
      const asc = sorting.asc === undefined ? true : sorting.asc;
      tmp = {
        asc: asc,
        icon: asc === false ? "sort-down" : "sort-up",
      };
    }
    setSorting(tmp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSorting, sortField, sortedField, sorting.asc]);

  const sortClicked = () => {
    let asc = sorting.asc === undefined ? true : !sorting.asc;
    setSorting({ ...sorting, asc: asc });
    doSort(sortField, asc);
  };

  return (
    <button className="transaction-row link" onClick={sortClicked}>
      {title} <FontAwesomeIcon icon={sorting.icon} />
    </button>
  );
}

export default SortableLink;
