import React from "react";
import NumberCard from "../../cards/NumberCard";

function Cards({ transactions }) {
  return (
    <div className="cards-container">
      <NumberCard
        icon="building"
        number={transactions?.records?.length || 0}
        title="Αντιπρόσωποι"
      />
      <NumberCard
        icon="receipt"
        number={transactions?.statistics?.transactionsSum || 0}
        title="Σύνολο συναλλαγών"
      />
      <NumberCard
        icon="money-bill"
        number={(transactions?.statistics?.commissionSum || 0).toFixed(1)}
        title="Σύνολο προμηθειών"
      />
    </div>
  );
}

export default Cards;
