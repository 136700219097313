import React from "react";
import { FormGroup, Input, Label } from "reactstrap";

function SearchField({ name, title, placeholder, search, setSearchFn }) {
    return (
        <FormGroup>
            <Label for={name} size="sm">
                {title}
            </Label>
            <Input
                type="search"
                bsSize="sm"
                placeholder={placeholder}
                name={name}
                value={search}
                onChange={(e) => setSearchFn(e.target.value)}
            />
        </FormGroup>
    );
}

export default SearchField;
