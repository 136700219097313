import React from "react";
import { Card, CardBody, CardTitle, CardSubtitle, Container } from "reactstrap";

function NotAvailable({ title = "Μη διαθέσιμη" }) {
    return (
        <Container fluid>
            <h2 className="page-header">{title}</h2>
            <Card color="warning">
                <CardBody>
                    <CardTitle tag="h5">Προσωρινά μη διαθέσιμη αναφορά</CardTitle>
                    <CardSubtitle className="mb-2 text-muted">
                        Η αναφορά είναι σε διαδικασία δημιουργίας από το Τμήμα
                        Μηχανογράφησης.
                        <br />
                        Όταν είναι διαθέσιμη, αυτή η σελίδα θα αντικατασταθεί αυτομάτως.
                    </CardSubtitle>
                </CardBody>
            </Card>
        </Container>
    );
}

export default NotAvailable;
