import { config } from "./Config";

const wsUri = () => {
  const loc = window.location;
  let uri = "ws:";

  if (loc.protocol === "https:") {
    uri = "wss:";
  }

  uri += `//${
    config?.WS_HOST ? config.WS_HOST : loc.host
  }/api/${config.API_VERSION}/ws`;

  return uri;
};

export class L4AWebSocket {
  constructor(identifier = "") {
    this.identifier = identifier;
    this.ws = null;
    this.onopen = null;
    this.onclose = null;
    this.onunauthorized = null;
    this.onerror = null;
    this.onmessage = null;
  }

  connect() {
    const uri = wsUri();
    // console.log("ws connecting to", uri);

    if (this.ws) {
      this.disconnect();
    }

    this.ws = new WebSocket(uri);

    this.ws.onopen = () => {
      // console.log(this.identifier, "connect");
      if (this.onopen) this.onopen();
    };
    this.ws.onclose = () => {
      // console.log(this.identifier, "disconnect");
      if (this.onclose) this.onclose();
    };
    this.ws.onerror = (error) => {
      // console.log(this.identifier, "error");
      if (this.onerror) this.onerror(error);
    };
    this.ws.onmessage = (msg) => {
      // console.log(this.identifier, msg);
      const data = JSON.parse(msg.data);
      if (data.error) {
        // console.log(data.error);
        if (data.error === "unauthorized") {
          if (this.onunauthorized) this.onunauthorized();
        } else {
          if (this.onerror) this.onerror(data.error);
        }
      } else {
        if (this.onmessage) this.onmessage(data);
      }
    };
  }

  get connected() {
    return this.ws && this.ws.readyState === this.ws.OPEN;
  }

  disconnect() {
    if (this.ws) {
      this.ws.close();
      this.ws = null;
    }
  }

  send(obj) {
    // console.log(this.identifier, "send", obj);
    if (this.ws) {
      this.ws.send(obj);
    }
  }
}
