import React from "react";
import SortableLink from "../../SortableLink";

function Header({ sortedField, doSort }) {
    return (
        <thead>
            <tr>
                <th />
                <th>
                    <SortableLink
                        title="Πηγή"
                        sortField="database"
                        sortedField={sortedField}
                        doSort={doSort}
                    />
                </th>
                <th>
                    <SortableLink
                        title="Εταιρία"
                        sortField="company"
                        sortedField={sortedField}
                        doSort={doSort}
                    />
                </th>
                <th>
                    <SortableLink
                        title="Περιοχή"
                        sortField="area"
                        sortedField={sortedField}
                        doSort={doSort}
                    />
                </th>
                <th>
                    <SortableLink
                        title="Πόλη"
                        sortField="city"
                        sortedField={sortedField}
                        doSort={doSort}
                    />
                </th>
                <th>
                    <SortableLink
                        title="Νομός"
                        sortField="nomos"
                        sortedField={sortedField}
                        doSort={doSort}
                    />
                </th>
                <th>
                    <SortableLink
                        title="Τ.Κ."
                        sortField="zipCode"
                        sortedField={sortedField}
                        doSort={doSort}
                    />
                </th>
                <th>ΑΦΜ</th>
                <th>
                    <SortableLink
                        title="Συσκευές"
                        sortField="totalDevices"
                        sortedField={sortedField}
                        doSort={doSort}
                    />
                </th>
                <th>
                    <SortableLink
                        title="Συναλλαγές"
                        sortField="totalTransactions"
                        sortedField={sortedField}
                        doSort={doSort}
                    />
                </th>
                <th>
                    <SortableLink
                        title="Προμήθεια"
                        sortField="commission"
                        sortedField={sortedField}
                        doSort={doSort}
                    />
                </th>
            </tr>
        </thead>
    );
}

export default Header;
