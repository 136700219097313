import writeXlsxFile from "write-excel-file";
import { createExcelHeader } from "../../export/common";

export const exportExcel = async (filename, state, statistics, records) => {
    const topHeader = createExcelHeader(state, statistics);

    const columns = [{ width: 22 }, { width: 13 }, { width: 13 }];

    const header = [
        { value: "Κατηγορία", fontWeight: "bold" },
        { value: "Συσκευές", fontWeight: "bold" },
        { value: "Συναλλαγές", fontWeight: "bold" },
    ];

    const data = records.map((item) => {
        return [
            { type: String, value: item.name },
            { type: Number, value: item.totalDevices },
            { type: Number, value: item.totalTransactions },
        ];
    });

    const file = await writeXlsxFile([...topHeader, header, ...data], {
        columns,
        fileName: filename,
    });
    return file;
};
