import React, { useContext, useEffect } from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import { AuthProvider, RequireAdmin, RequireAuth } from "./Auth";
import { WebsocketsProvider } from "./WebsocketsProvider";
import PageNav from "./components/PageNav";
import Sidebar from "./components/Sidebar";
import Footer from "./components/Footer";
import NotAvailable from "./components/NotAvailable";
import User from "./components/User";
import LoginForm from "./LoginForm";
import ContactUs from "./ContactUs";
import Errors from "./components/Errors";
import TransactionsTotal from "./components/Transactions/Total/List";
import TransactionsDetailed from "./components/Transactions/Detailed/List";
import TransactionsTotalPerCategory from "./components/Transactions/TotalPerCategory/List";
import TransactionsTotalPerPaymentMethod from "./components/Transactions/TotalPerPaymentMethod/List";
import TransactionsTotalValuesPerPaymentMethod from "./components/Transactions/TotalValuesPerPaymentMethod/List";
import TransactionsTotalPerDealer from "./components/Transactions/TotalPerDealer/List";
import TransactionsUpload from "./components/Transactions/Upload";
import EditSalesmen from "./components/settings/EditSalesmen";
import EditDealers from "./components/settings/EditDealers";
import Downloads from "./components/Downloads/Downloads";
import Store, { Context } from "./Store";
import {
  ADD_ALERT,
  DELETE_ALERT,
  SET_AREAS,
  SET_AVAILABLE_BANKS,
  SET_AVAILABLE_CATEGORIES,
  SET_AVAILABLE_DBS,
  SET_CITIES,
  SET_DOWNLOADS,
  SET_LIMITS,
  SET_NOMOI,
  SET_PRESET_DATES,
  SET_SALESMEN,
  SET_ZIPCODES,
} from "./Reducer";
import "./fa-icons";
import "./alert.css";
import "./App.css";
import { UncontrolledAlert } from "reactstrap";
import Dashboard from "./components/Dashboard/Dashboard";
import { config } from "./Config";
import useAuth from "./useAuth";
import { useWebsockets } from "./useWebsockets";
import { useApiCall } from "./useApiCall";
import { useAlerts } from "./useAlerts";
import Announcements from "./components/Announcements";

function App() {
  return (
    <Store>
      <AuthProvider>
        <WebsocketsProvider>
          <Layout />
        </WebsocketsProvider>
      </AuthProvider>
    </Store>
  );
}

function Layout() {
  const [state, dispatch] = useContext(Context);
  const [connected, send, receive] = useWebsockets();
  const getDownloads = useApiCall("/downloads");
  const [displayAlerts, alertsCleanup, alertsNewVersion] = useAlerts();

  // check periodically for newer version on the server
  useEffect(() => {
    const recurring = setInterval(() => {
      send("version", null, (msg) => {
        if ((msg?.payload || config.VERSION) !== config.VERSION) {
          alertsNewVersion();
        }
      });
    }, 10000);
    return () => clearInterval(recurring);
  });

  // cleanup expired alerts every second
  useEffect(() => {
    const recurring = setInterval(() => alertsCleanup(), 1000);
    return () => clearInterval(recurring);
  });

  useEffect(() => {
    if (connected) {
      // receive((msg) => console.log("app global receive:", msg))
      send(
        "databases",
        null,
        (msg) => dispatch({ type: SET_AVAILABLE_DBS, payload: msg.payload }),
      );
      send(
        "banks",
        null,
        (msg) => dispatch({ type: SET_AVAILABLE_BANKS, payload: msg.payload }),
      );
      send(
        "preset_dates",
        null,
        (msg) => dispatch({ type: SET_PRESET_DATES, payload: msg.payload }),
      );
      send(
        "limits",
        null,
        (msg) => dispatch({ type: SET_LIMITS, payload: msg.payload }),
      );
      send(
        "areas",
        null,
        (msg) => dispatch({ type: SET_AREAS, payload: msg.payload }),
      );
      send(
        "cities",
        null,
        (msg) => dispatch({ type: SET_CITIES, payload: msg.payload }),
      );
      send(
        "nomoi",
        null,
        (msg) => dispatch({ type: SET_NOMOI, payload: msg.payload }),
      );
      send(
        "zipcodes",
        null,
        (msg) => dispatch({ type: SET_ZIPCODES, payload: msg.payload }),
      );
      send(
        "salesmen",
        null,
        (msg) => dispatch({ type: SET_SALESMEN, payload: msg.payload }),
      );
    }
  }, [connected, send, receive, dispatch]);

  useEffect(() => {
    if (connected) {
      send(
        "categories",
        { database: state.database },
        (msg) =>
          dispatch({ type: SET_AVAILABLE_CATEGORIES, payload: msg.payload }),
      );
    }
  }, [state.database, connected, send, receive, dispatch]);

  useEffect(() => {
    const abort = new AbortController();
    getDownloads(
      JSON.stringify({
        user: { userIds: state.userIds },
      }),
      "",
      { signal: abort.signal },
    ).then((resp) => {
      dispatch({ type: SET_DOWNLOADS, payload: resp });
    });
    return () => abort.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.access_token, state.userIds]);

  return (
    <Router>
      <div className="outer-container">
        {state.access_token && <PageNav />}
        {!state.access_token ? <LoginForm /> : (
          <div className="main-container">
            {state.access_token && <Sidebar />}
            <div className="content">
              <SiteRouting />
            </div>
          </div>
        )}
        {state.access_token && <Footer />}
        {displayAlerts()}
      </div>
    </Router>
  );
}

function Logout({ logoutFn }) {
  useEffect(() => logoutFn());
  return <Navigate to="/" />;
}

function SiteRouting() {
  const auth = useAuth();

  // <Route path="/login" element={<LoginForm />} />
  return (
    <Routes>
      <Route
        path="/"
        element={
          <RequireAuth>
            <Outlet />
          </RequireAuth>
        }
      >
        <Route index element={<Navigate to="/dashboard" />} />

        <Route path="dashboard" element={<Outlet />}>
          <Route path="" element={<Dashboard />} />
          <Route path="posit" element={<Dashboard posit={true} />} />
        </Route>

        <Route path="user" element={<User />} />

        <Route path="tr" element={<Outlet />}>
          <Route path="total" element={<TransactionsTotal />} />
          <Route
            path="total/posit"
            element={<TransactionsTotal posit={true} />}
          />
          <Route
            path="detailed/ete"
            element={<TransactionsDetailed ete={true} />}
          />
          <Route
            path="detailed/other"
            element={<TransactionsDetailed ete={false} />}
          />
          <Route
            path="detailed/posit"
            element={<TransactionsDetailed posit={true} />}
          />
          <Route
            path="categories"
            element={<TransactionsTotalPerCategory />}
          />
          <Route
            path="pm"
            element={<TransactionsTotalPerPaymentMethod />}
          />
          <Route
            path="vpm"
            element={<TransactionsTotalValuesPerPaymentMethod />}
          />
          <Route
            path="dealers"
            element={
              <RequireAdmin>
                <TransactionsTotalPerDealer />
              </RequireAdmin>
            }
          />
          <Route
            path="dealers/posit"
            element={<TransactionsTotalPerDealer posit={true} />}
          />
          <Route
            path="upload"
            element={
              <RequireAdmin>
                <TransactionsUpload />
              </RequireAdmin>
            }
          />
        </Route>

        <Route
          path="settings"
          element={
            <RequireAdmin>
              <Outlet />
            </RequireAdmin>
          }
        >
          <Route path="salesmen" element={<EditSalesmen />} />
          <Route path="dealers" element={<EditDealers />} />
          <Route path="errors" element={<Errors />} />
        </Route>

        <Route
          path="contact"
          element={<ContactUs />}
        />

        <Route path="announcements" element={<Announcements />} />
        <Route path="downloads" element={<Downloads />} />
        <Route path="logout" element={<Logout logoutFn={auth.signout} />} />
        <Route path="notavailable" element={<NotAvailable />} />
      </Route>
    </Routes>
  );
}

export default App;
