import { Version } from "./version";

// NOTE
//
// environment variable picked up by react *must* be named as REACT_APP_xxx !!!
// and is only picked up during "yarn build" or "yarn start"

export const TriliaAppMode = process.env.REACT_APP_TRILIA_APP === "1"
export const TriliaAppDatabase = process.env.REACT_APP_TRILIA_DB
export const TriliaAppDownloadURL = process.env.REACT_APP_TRILIA_DOWNLOAD_URL || "https://setup.triliaspot.com/updated/"

const prod = {
    VERSION: Version,
    // API_URL: "https://21.126.10.140/api/v1",
    // WS_URL: "wss://20.126.10.140/api/v1/ws",
    // API_URL: process.env.REACT_APP_API_URL, // "http://l4a-api.rnd.matdev.gr/api/v1",
    // WS_URL: "ws://l4a-api.rnd.matdev.gr/api/v1/ws",
    API_VERSION: "v1",
    API_URL: "/api/v1",
};

const dev = {
    VERSION: "dev",
    API_VERSION: "v1",
    API_URL: "http://localhost:8888/api/v1",
    WS_HOST: "localhost:8888",
};

export const config = process.env.NODE_ENV === "development" ? dev : prod;
