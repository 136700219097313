import React, { useState, useEffect } from "react";
import {
  Button,
  FormGroup,
  Input,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import "./SelectObjectDialog.css";
import Pagination from "../Pagination/Pagination";
import Spinner from "../../Spinner";
import { usePagination } from "../Pagination/usePagination";

const SelectObjectDialog = ({
  records,
  displayFields,
  headerNames,
  valueField,
  title = "Επιλογή από λίστα",
  placeholder = "Αναζήτηση κειμένου",
  allowFiltering = true,
  isOpen = false,
  closeFn = null,
  selectFn = null,
  disableField = undefined,
  disableOnValue = undefined,
}) => {
  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState("");
  const [selectedName, setSelectedName] = useState("");
  const [filtered, setFiltered] = useState(records);
  const [filteringNow, setFilteringNow] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const pagination = usePagination({
    currentPage,
    pageSize,
    records: filtered,
  });

  useEffect(() => {
    setFilteringNow(true);
    if (!allowFiltering) {
      setFiltered(records);
      setFilteringNow(false);
      return;
    } else if (search === "") {
      setFiltered([]);
      setFilteringNow(false);
      return;
    }
    const ls = search.toLowerCase();
    const f = records.filter((item) => {
      // return item["fullName"].toLowerCase().indexOf(ls) !== -1;
      for (let i = 0; i < displayFields.length; ++i) {
        const fld = item[displayFields[i]]?.toLowerCase();
        if (fld && fld.indexOf(ls) !== -1) {
          return true;
        }
      }
      return false;
    });
    // console.log(`${f.length} matches out of ${records.length} records`);
    setFiltered(f);
    setFilteringNow(false);
  }, [records, search, displayFields, allowFiltering]);

  const highlightMatch = (text) => {
    if (!allowFiltering) {
      return text;
    } else if (!text) {
      return "";
    }
    const index = text.toLowerCase().indexOf(search.toLowerCase());
    if (index !== -1) {
      const before = text.substring(0, index);
      const match = text.substring(index, index + search.length);
      const after = text.substring(index + search.length);
      return (
        <>
          <span>{before}</span>
          <span className="search-text-highlight">{match}</span>
          <span>{after}</span>
        </>
      );
    }
    return text;
  };

  return (
    <Modal isOpen={isOpen} toggle={closeFn} size="lg">
      <ModalHeader toggle={closeFn}>{title}</ModalHeader>
      <ModalBody className="select-object-body">
        {allowFiltering && (
          <FormGroup>
            <Input
              id="searchName"
              name="searchName"
              placeholder={placeholder}
              type="text"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              autoFocus
            />
          </FormGroup>
        )}
        {filteringNow && <Spinner />}
        {!filteringNow && filtered.length > 0 && (
          <div className="select-object-list-container">
            <Table
              className="select-object-table"
              size="sm"
              striped
              hover
              responsive
            >
              <thead>
                <tr>
                  {headerNames.map((f) => {
                    return <th key={f}>{f}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                {pagination.records?.map((v) => {
                  const disabled =
                    disableField !== undefined &&
                    v[disableField] === disableOnValue;
                  console.log(disableField, disableOnValue, v[disableField]);
                  return (
                    <tr
                      className={
                        (v[valueField] === selected ? "selected" : "") +
                        (disabled ? " disabled" : "")
                      }
                      key={v[valueField]}
                      onClick={() => {
                        // toggle selection
                        if (!disabled) {
                          if (selected === v[valueField]) {
                            setSelected("");
                            setSelectedName("");
                          } else {
                            setSelected(v[valueField]);
                            setSelectedName(v[displayFields[0]]);
                          }
                        }
                      }}
                    >
                      {displayFields?.map((f) => {
                        return <td key={f}>{highlightMatch(v[f])}</td>;
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        )}
        <Pagination
          pagination={pagination}
          currentPage={currentPage}
          pageSize={pageSize}
          onPageChange={setCurrentPage}
          setPageSize={setPageSize}
        />
      </ModalBody>
      <ModalFooter>
        {selectedName.length > 0 && (
          <span className="selected-record text-success">
            {`Έχετε επιλέξει την εγγραφή "${selectedName}"`}
          </span>
        )}
        {selectedName.length === 0 && (
          <span className="selected-record text-danger">
            Επιλέξτε μία εγγραφή&hellip;
          </span>
        )}
        {filtered.length === 0 && (
          <span className="selected-record text-danger">
            Αναζητήστε κείμενο για να δείτε εδώ τα αποτελέσματα.
          </span>
        )}
        <Button
          color="primary"
          size="sm"
          disabled={!selected}
          onClick={() => {
            selectFn(selected);
          }}
        >
          Επιλογή
        </Button>{" "}
        <Button size="sm" onClick={closeFn}>
          Ακύρωση
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default SelectObjectDialog;
