import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Collapse,
  Form,
  Input,
  Col,
  Row,
  Nav,
  NavItem,
  NavLink,
  FormGroup,
  Label,
} from "reactstrap";
import DateFormField from "./DateFormField";
import NumberFormField from "./NumberFormField";
import SelectFormField from "./SelectFormField";
import SearchField from "./SearchField";
import CompletionTextFormField from "./CompletionTextFormField";
import { Context } from "../../Store";
import { SET_FLT_ALL, RESET_FILTERS } from "../../Reducer";
import {
  datesTextDescr,
  transactionsTextDescr,
  locationTextDescr,
  searchTextDescr,
  devicesTextDescr,
} from "./funcs";
import "./filters.css";

function Filters({
  statistics,
  devicesVisible = true,
  datesVisible = true,
  transactionsVisible = true,
  locationVisible = true,
  searchVisible = false,
  autoClose = false,
  defaultOpen = false,
}) {
  const [state, dispatch] = useContext(Context);
  const [filtersOpen, setFiltersOpen] = useState(defaultOpen);
  const [filtersText, setFiltersText] = useState(null);
  const [cityText, setCityText] = useState(state.filters.location.city);
  const [areaText, setAreaText] = useState(state.filters.location.area);
  const [nomosText, setNomosText] = useState(state.filters.location.nomos);
  const [zipCodeText, setZipCodeText] = useState(
    state.filters.location.zipCode
  );
  const [op, setOp] = useState(state.filters.location.op);
  const [range, setRange] = useState(state.filters.date.range);
  const [startDate, setStartDate] = useState(state.filters.date.custom.start);
  const [endDate, setEndDate] = useState(state.filters.date.custom.end);
  const [searchText, setSearchText] = useState(state.searchText);
  const [minTransactions, setMinTransactions] = useState(
    state.filters.transactions.min
  );
  const [maxTransactions, setMaxTransactions] = useState(
    state.filters.transactions.max
  );
  const [devicesActive, setDevicesActive] = useState(
    state.filters.devices.active
  );
  const [devicesInactive, setDevicesInactive] = useState(
    state.filters.devices.inactive
  );

  const [activePage, setActivePage] = useState(0);

  // keep local state vars in sync with global state ones
  useEffect(
    () => setCityText(state.filters.location.city),
    [setCityText, state.filters.location.city]
  );
  useEffect(
    () => setAreaText(state.filters.location.area),
    [setAreaText, state.filters.location.area]
  );
  useEffect(
    () => setNomosText(state.filters.location.nomos),
    [setNomosText, state.filters.location.nomos]
  );
  useEffect(
    () => setZipCodeText(state.filters.location.zipCode),
    [setZipCodeText, state.filters.location.zipCode]
  );
  useEffect(
    () => setOp(state.filters.location.op),
    [setOp, state.filters.location.op]
  );
  useEffect(
    () => setRange(state.filters.date.range),
    [setRange, state.filters.date.range]
  );
  useEffect(
    () => setStartDate(state.filters.date.custom.start),
    [setStartDate, state.filters.date.custom.start]
  );
  useEffect(
    () => setEndDate(state.filters.date.custom.end),
    [setEndDate, state.filters.date.custom.end]
  );
  useEffect(
    () => setMinTransactions(state.filters.transactions.min),
    [setMinTransactions, state.filters.transactions.min]
  );
  useEffect(
    () => setMaxTransactions(state.filters.transactions.max),
    [setMaxTransactions, state.filters.transactions.max]
  );
  useEffect(
    () => setSearchText(state.searchText),
    [setSearchText, state.searchText]
  );

  useEffect(() => {
    let texts = [];
    if (datesVisible) {
      const df = datesTextDescr(state, statistics, range);
      if (df) {
        texts.push(df);
      }
    }
    if (devicesVisible) {
      const df = devicesTextDescr(state);
      if (df) {
        texts.push(df);
      }
    }
    if (locationVisible) {
      const lf = locationTextDescr(state);
      if (lf) {
        texts.push(lf);
      }
    }
    if (transactionsVisible) {
      const tf = transactionsTextDescr(state, statistics);
      if (tf) {
        texts.push(tf);
      }
    }
    if (searchVisible) {
      const tf = searchTextDescr(state);
      if (tf) {
        texts.push(tf);
      }
    }
    const result = texts.map((v, index) => (
      <span key={"filter-text-" + index}>
        {v}
        {index < texts.length - 1 && ", "}
      </span>
    ));
    setFiltersText(result);
  }, [
    state,
    statistics,
    range,
    datesVisible,
    devicesVisible,
    locationVisible,
    transactionsVisible,
    searchVisible,
  ]);

  const applyFilters = () => {
    if (autoClose) {
      setFiltersOpen(false)
    }
    dispatch({
      type: SET_FLT_ALL,
      payload: {
        searchText: searchText,
        filters: {
          devices: {
            active: devicesActive,
            inactive: devicesInactive,
          },
          location: {
            city: cityText,
            area: areaText,
            nomos: nomosText,
            zipCode: zipCodeText,
            op: op,
          },
          date: {
            range: range, // range: 0=custom, 1=yesterday, 2=thisweek, 3=prevweek, 4=thismonth, 5=prevmonth
            custom: {
              start: startDate,
              end: endDate,
            },
          },
          transactions: {
            min: minTransactions,
            max: maxTransactions,
          },
        },
      },
    });
  };

  const datePresets = [
    "Άλλη περίοδος",
    "Χθες",
    "Τρέχουσα εβδομάδα",
    "Προηγούμενη εβδομάδα",
    "Τρέχων μήνας",
    "Προηγούμενος μήνας",
  ];

  return (
    <div className="filters-container">
      {/* header collapse line */}
      <Row className="filters-header">
        <Col
          className="click-to-open"
          md={9}
          onClick={() => {
            setFiltersOpen(!filtersOpen);
          }}
        >
          <span className="filters-text">
            <FontAwesomeIcon icon="filter" />
            {" Φίλτρα: "}
            {filtersText}
          </span>
        </Col>
        <Col style={{ textAlign: "right" }}>
          <Button size="sm" color="default" onClick={applyFilters}>
            <FontAwesomeIcon icon="filter" /> Εφαρμογή
          </Button>
          <Button
            size="sm"
            color="default"
            onClick={() => {
              dispatch({ type: RESET_FILTERS });
              // setFiltersOpen(false);
            }}
          >
            <FontAwesomeIcon icon="broom" /> Καθαρισμός
          </Button>
        </Col>
      </Row>
      <Collapse isOpen={filtersOpen}>
        <div className="filters-content">
          <Nav pills>
            {datesVisible && (
              <NavItem>
                <NavLink
                  active={activePage === 0}
                  onClick={() => setActivePage(0)}
                >
                  Χρονική περίοδος
                </NavLink>
              </NavItem>
            )}
            {devicesVisible && (
              <NavItem>
                <NavLink
                  active={activePage === 1}
                  onClick={() => setActivePage(1)}
                >
                  Συσκευές
                </NavLink>
              </NavItem>
            )}
            {locationVisible && (
              <NavItem>
                <NavLink
                  active={activePage === 2}
                  onClick={() => setActivePage(2)}
                >
                  Τοποθεσία
                </NavLink>
              </NavItem>
            )}
            {transactionsVisible && (
              <NavItem>
                <NavLink
                  active={activePage === 3}
                  onClick={() => setActivePage(3)}
                >
                  Αρ. συναλλαγών
                </NavLink>
              </NavItem>
            )}
            {searchVisible && (
              <NavItem>
                <NavLink
                  active={activePage === 4}
                  onClick={() => setActivePage(4)}
                >
                  Αναζήτηση κειμένου
                </NavLink>
              </NavItem>
            )}
          </Nav>
          <Form className="filters-form">
            <Row>
              {activePage === 0 && (
                <>
                  <Col md={3}>
                    <SelectFormField
                      name="datesPreset"
                      title="Προκαθορισμένες περίοδοι"
                      items={datePresets}
                      selected={range}
                      setSelectedFn={(val) => {
                        const range = parseInt(val);
                        setRange(range);
                        if (range !== 0) {
                          setStartDate(state.presetDates[range].start);
                          setEndDate(state.presetDates[range].end);
                        }
                      }}
                    />
                  </Col>
                  <Col md={2}>
                    <DateFormField
                      name="startDate"
                      title="Από ημ/νία"
                      disabled={range !== 0}
                      date={startDate}
                      setDateFn={(val) => setStartDate(val)}
                    />
                  </Col>
                  <Col md={2}>
                    <DateFormField
                      name="endDate"
                      title="Έως ημ/νία"
                      disabled={range !== 0}
                      date={endDate}
                      setDateFn={(val) => setEndDate(val)}
                    />
                  </Col>
                  <Col md={5}>
                    <br/>
                    Μέγιστο επιτρεπτό εύρος: {state.limits?.maxRangeInDays || "?"} ημέρες<br />
                    Παλαιότερη διαθέσιμη ημερομηνία: {state.limits?.earlierAllowedDate ? new Date(state.limits?.earlierAllowedDate).toLocaleDateString("el-GR", { dateStyle: "medium" }) : "Άγνωστο"}
                  </Col>
                </>
              )}
              {activePage === 1 && (
                <>
                  <Col md={3}>
                    <FormGroup tag="fieldset">
                      <FormGroup check>
                        <Input
                          name="activedevs"
                          type="radio"
                          checked={devicesActive && !devicesInactive}
                          onChange={(v) => {
                            setDevicesActive(v.target.checked);
                            setDevicesInactive(!v.target.checked);
                          }}
                        />
                        <Label check>Μόνον οι ενεργές συσκευές</Label>
                      </FormGroup>
                      <FormGroup check>
                        <Input
                          name="inactivedevs"
                          type="radio"
                          checked={devicesInactive && !devicesActive}
                          onChange={(v) => {
                            setDevicesActive(!v.target.checked);
                            setDevicesInactive(v.target.checked);
                          }}
                        />
                        <Label check>Μόνον οι ανενεργές συσκευές</Label>
                      </FormGroup>
                      <FormGroup check>
                        <Input
                          name="alldevs"
                          type="radio"
                          checked={devicesActive && devicesInactive}
                          onChange={(v) => {
                            if (v.target.checked) {
                              setDevicesActive(true);
                              setDevicesInactive(true);
                            }
                          }}
                        />
                        <Label check>Όλες οι συσκευές</Label>
                      </FormGroup>
                    </FormGroup>
                  </Col>
                </>
              )}
              {activePage === 2 && (
                <>
                  <Col md={1}>
                    <SelectFormField
                      name="locOp"
                      title="Συντελεστής"
                      items={["και", "ή"]}
                      selected={op === "OR" ? 1 : 0}
                      setSelectedFn={(val) =>
                        setOp(parseInt(val) === 1 ? "OR" : "AND")
                      }
                    />
                  </Col>
                  <Col md={2}>
                    <CompletionTextFormField
                      name="area"
                      title="Περιοχή"
                      text={areaText}
                      setTextFn={(v) => setAreaText(v)}
                      autocompleteItems={state.areas}
                    />
                  </Col>
                  <Col md={2}>
                    <CompletionTextFormField
                      name="city"
                      title="Πόλη"
                      text={cityText}
                      setTextFn={(v) => setCityText(v)}
                      autocompleteItems={state.cities}
                    />
                  </Col>
                  <Col md={2}>
                    <CompletionTextFormField
                      name="nomos"
                      title="Νομός"
                      text={nomosText}
                      setTextFn={(v) => setNomosText(v)}
                      autocompleteItems={state.nomoi}
                    />
                  </Col>
                  <Col md={2}>
                    <CompletionTextFormField
                      name="zipCode"
                      title="Τ.Κ."
                      text={zipCodeText}
                      setTextFn={(v) => setZipCodeText(v)}
                      autocompleteItems={state.zipcodes}
                    />
                  </Col>
                </>
              )}
              {activePage === 3 && (
                <>
                  <Col md={2}>
                    <NumberFormField
                      name="minTransactionsNum"
                      title="Από συναλλαγές"
                      number={minTransactions}
                      setNumberFn={(val) => setMinTransactions(val)}
                    />
                  </Col>
                  <Col md={2}>
                    <NumberFormField
                      name="maxTransactionsNum"
                      title="Έως συναλλαγές"
                      number={maxTransactions}
                      setNumberFn={(val) => setMaxTransactions(val)}
                    />
                  </Col>
                </>
              )}
              {activePage === 4 && (
                <>
                  <Col colSpan={4}>
                    <SearchField
                      name="search"
                      title="Ελεύθερο κείμενο"
                      placeholder="Αντιπρόσωπος, πελάτης, ΑΦΜ ή σειριακός αριθμός..."
                      search={searchText}
                      setSearchFn={(v) => setSearchText(v)}
                    />
                  </Col>
                </>
              )}
            </Row>
          </Form>
        </div>
      </Collapse>
    </div>
  );
}

export default Filters;
