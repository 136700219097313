import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UncontrolledTooltip } from "reactstrap";

function Row({ data }) {
    return (
        <tr className="transaction-row">
            <td id={data.deviceSerialNo}>
                {data.company}
                <UncontrolledTooltip
                    target={data.deviceSerialNo}
                    placement="bottom"
                >
                    {data?.phoneNumber && (
                        <>
                            <FontAwesomeIcon icon="phone-alt" />{" "}
                            {data.phoneNumber}
                            <br />
                        </>
                    )}
                    {data?.email && (
                        <>
                            <FontAwesomeIcon icon="envelope" /> {data.email}
                        </>
                    )}
                </UncontrolledTooltip>
            </td>
            <td>{data.area}</td>
            <td>{data.city}</td>
            <td>{data.nomos}</td>
            <td>{data.zipCode}</td>
            <td>{data.tin}</td>
            <td>{data.deviceSerialNo}</td>
            <td>
                {data.categoryGroup === "public_sector"
                    ? "Δημόσιο/Ασφ.Ταμεία"
                    : "Ιδιωτικός τομέας"}
            </td>
            <td>{data.method === "CARD" ? "Κάρτα" : "Μετρητά"}</td>
            <td align="right">{data.transactions}</td>
            <td align="right">{data.commissions.toFixed(1)}</td>
        </tr>
    );
}

export default Row;
