import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Context } from "./Store";
import { L4AWebSocket } from "./ws";

export const WebsocketsContext = createContext();

const { Provider } = WebsocketsContext;

let send_callbacks = new Map();
let receive_callbacks = new Map();

export const WebsocketsProvider = ({ children }) => {
  const [state] = useContext(Context);
  const [connected, setConnected] = useState(false);
  const [connection, setConnection] = useState(null);

  const identity = "global";

  // start websocket connection
  useEffect(() => {
    let conn = connection;
    if (!conn) {
      conn = new L4AWebSocket(identity || "<unnamed>");
      setConnection(conn);
    }

    // console.log("callbacks", callbacks)
    conn.onopen = () => {
      // console.log(`${identity}: connected to websocket`);
      setConnected(true);
    };
    conn.onclose = () => {
      // console.log(`${identity}: disconnected from websocket`);
      setConnected(false);
    };
    conn.onmessage = (msg) => {
      // console.log(`${identity}: websocket:`, msg);
      if (!msg?.type) {
        return;
      }
      if (msg.type === "error") {
        console.log(`websocket error: ${msg?.userData}:`, msg.error);
        if (send_callbacks.has(msg?.userData)) {
          send_callbacks.delete(msg?.userData); // no longer needed
        }
      } else {
        if (send_callbacks.has(msg?.userData)) {
          send_callbacks.get(msg?.userData)(msg);
          send_callbacks.delete(msg?.userData); // no longer needed
          // console.log(send_callbacks.size, "send callbacks registered")
        } else {
          receive_callbacks.forEach((f) => f(msg));
        }
      }
    };

    // if (!conn.connected) {
    conn.connect();
    // }

    return () => conn.disconnect();
  }, [connection]);

  // re-start websocket connection if disconnected
  useEffect(() => {
    const t = setInterval(() => {
      if (connection && !connection.connected) {
        connection.connect();
      }
      // during development, with live reload,
      // the 'connected' state can become de-synchronized with
      // the 'connection.connected' state
      setConnected(connection?.connected);
    }, 2000);
    return () => clearInterval(t);
  }, [connection]);

  const receive = useCallback((identifier, callback) => {
    const ud = `${identity}-${identifier}`;
    // console.log(ud, callback)
    if (callback) {
      receive_callbacks.set(ud, callback);
      // console.log(receive_callbacks.size, "receive callbacks registered")
    }
  }, []);

  const send = useCallback(
    (type, args = {}, callback = null) => {
      // NOTE: unique identifier can be randomly setup here...
      //       'send' is expected to return one answer anyway
      const ud = require("uuid").v4();
      const obj = JSON.stringify({
        token: state.access_token,
        type: type,
        args: args,
        userData: ud,
      });
      if (callback) {
        send_callbacks.set(ud, callback);
        // console.log(send_callbacks.size, "send callbacks registered")
      }
      try {
        if (connection) {
          connection.send(obj);
        }
      } catch (reason) {
        console.log(reason);
      }
    },
    [connection, state.access_token],
  );

  return (
    <Provider value={{ connected, send, receive }}>
      {children}
    </Provider>
  );
};
