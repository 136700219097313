import React, { useState, useEffect } from "react";
import { FormGroup, Label } from "reactstrap";
import Autocomplete from "react-autocomplete";

function CompletionTextFormField({
    name = "",
    title = "",
    text = "",
    setTextFn = undefined,
    placeholder = "",
    disabled = false,
    autocompleteItems = [],
}) {
    const [filtered, setFiltered] = useState(autocompleteItems);

    useEffect(() => {
        const search = text.toLowerCase();
        setFiltered(
            autocompleteItems.filter((v) => v.toLowerCase().indexOf(search) !== -1)
        );
    }, [text, setFiltered, autocompleteItems]);

    const autocompleteItem = (item, isHighlighted) => (
        <div style={{ background: isHighlighted ? "lightgray" : "white" }}>
            {item}
        </div>
    );

    return (
        <FormGroup>
            <Label for={name} size="sm">
                {title}
            </Label>
            <Autocomplete
                type="text"
                id={name}
                name={name}
                disabled={disabled}
                bsSize="small"
                getItemValue={(item) => item}
                items={filtered}
                inputProps={{ className: "form-control", placeholder: placeholder }}
                renderItem={autocompleteItem}
                value={text}
                onChange={(e) => {
                    setTextFn(e.target.value);
                }}
                onSelect={(val) => setTextFn(val)}
            />
        </FormGroup>
    );
}

export default CompletionTextFormField;
