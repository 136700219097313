import React from "react";
import classnames from "classnames";
import { DOTS } from "./usePagination";
import PaginationText from "./Text";
import PageSizeDropdown from "./PageSizeDropdown";
import "./pagination.scss";

function Pagination({
    onPageChange,
    currentPage,
    className,
    pagination,
}) {
    if (pagination.pages === undefined) {
        return <></>;
    }

    // If there are less than 2 times in pagination range we shall not render the component
    if (currentPage === 0 || pagination.pages.length < 2) {
        return null;
    }

    const onNext = () => {
        onPageChange(currentPage + 1);
    };

    const onPrevious = () => {
        onPageChange(currentPage - 1);
    };

    let dotIndex = 0;
    let lastPage = pagination.pages[pagination.pages.length - 1];
    return (
        <div className="pagination-wrapper">
            <ul
                className={classnames("pagination-container", {
                    [className]: className,
                })}
            >
                {/* Left navigation arrow */}
                <li
                    key={"row-left-arrow"}
                    className={classnames("pagination-item", {
                        disabled: currentPage === 1,
                    })}
                    onClick={onPrevious}
                >
                    <div className="arrow left" />
                </li>
                {pagination.pages.map((pageNumber) => {
                    // If the pageItem is a DOT, render the DOTS unicode character
                    if (pageNumber === DOTS) {
                        return (
                            <li
                                key={"row-dot-" + dotIndex++}
                                className="pagination-item dots"
                            >
                                &#8230;
                            </li>
                        );
                    }

                    // Render our Page Pills
                    return (
                        <li
                            key={"row" + pageNumber}
                            className={classnames("pagination-item", {
                                selected: pageNumber === currentPage,
                            })}
                            onClick={() => onPageChange(pageNumber)}
                        >
                            {pageNumber}
                        </li>
                    );
                })}
                {/*  Right Navigation arrow */}
                <li
                    key={"row-right-arrow"}
                    className={classnames("pagination-item", {
                        disabled: currentPage === lastPage,
                    })}
                    onClick={onNext}
                >
                    <div className="arrow right" />
                </li>
            </ul>
            <div className="pagination-block-wrapper">
              <PaginationText
                  firstRecord={pagination.startRecord}
                  lastRecord={pagination.endRecord}
                  totalRecords={pagination.totalRecords}
              />
              <PageSizeDropdown />
            </div>
        </div>
    );
}

export default Pagination;
