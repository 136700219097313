export const datesTextDescr = (state, statistics, range) => {
    let dates = statistics?.date;
    if (!dates?.start || !dates?.end) {
        if (range !== 0) {
            dates = state.presetDates[range];
        } else {
            dates = {
                start: state.filters.date.custom.start,
                end: state.filters.date.custom.end,
            };
        }
    }
    if (dates?.start === dates?.end) {
        // single date
        return (
            <>
                {"για την "}
                <strong>
                    {new Date(dates?.start).toLocaleDateString("el-GR")}
                </strong>
            </>
        );
    } else {
        // two dates
        return (
            <>
                {"από "}
                <strong>
                    {new Date(dates?.start).toLocaleDateString("el-GR")}
                </strong>
                {" έως "}
                <strong>
                    {new Date(dates?.end).toLocaleDateString("el-GR")}
                </strong>
            </>
        );
    }
};

export const devicesTextDescr = (state) => {
    const dev = state.filters.devices;
    if (dev.active && dev.inactive) {
        return "όλες οι συσκευές";
    } else if (dev.active) {
        return "οι ενεργές συσκευές";
    } else {
        return "οι ανενεργές συσκευές";
    }
};

export const transactionsTextDescr = (state, statistics) => {
    const all =
        state.filters.transactions.min === 1 &&
        state.filters.transactions.max === -1;

    if (all) {
        return <>όλες οι συναλλαγές</>;
    }

    const zeros =
        state.filters.transactions.min <= 0 &&
        state.filters.transactions.max <= 0;

    if (zeros) {
        return <>μηδενικές συναλλαγές</>;
    }

    return (
        <>
            {"από "}
            <strong>{statistics?.transactions?.min || 0}</strong>
            {" έως "}
            <strong>{statistics?.transactions?.max || 0}</strong>
            {"συναλλαγές"}
        </>
    );
};

export const locationTextDescr = (state) => {
    const loc = state.filters.location;
    const op = loc.op === "OR" ? " ή " : " και ";
    if (loc.nomos === "" && loc.city === "" && loc.area === "") {
        return <>όλες οι περιοχές, πόλεις και νομοί</>;
    }
    if (loc.nomos !== "" && loc.city === "" && loc.area === "") {
        return (
            <>
                {"νομός "}
                <strong>{loc.nomos}</strong>
            </>
        );
    } else if (loc.nomos === "" && loc.city !== "" && loc.area === "") {
        return (
            <>
                {"πόλη "}
                <strong>{loc.city}</strong>
            </>
        );
    } else if (loc.nomos === "" && loc.city === "" && loc.area !== "") {
        return (
            <>
                {"περιοχή "}
                <strong>{loc.area}</strong>
            </>
        );
    } else if (loc.nomos !== "" && loc.city !== "" && loc.area === "") {
        return (
            <>
                {"νομός "}
                <strong>{loc.nomos}</strong>
                {op}
                {"πόλη "}
                <strong>{loc.city}</strong>
            </>
        );
    } else if (loc.nomos !== "" && loc.city === "" && loc.area !== "") {
        return (
            <>
                {"νομός "}
                <strong>{loc.nomos}</strong>
                {op}
                {"περιοχή "}
                <strong>{loc.area}</strong>
            </>
        );
    } else if (loc.nomos === "" && loc.city !== "" && loc.area !== "") {
        return (
            <>
                {"πόλη "}
                <strong>{loc.city}</strong>
                {op}
                {"περιοχή "}
                <strong>{loc.area}</strong>
            </>
        );
    }
    return (
        <>
            {"νομός "}
            <strong>{loc.nomos}</strong>
            {op}
            {"πόλη "}
            <strong>{loc.city}</strong>
            {op}
            {"νομός "}
            <strong>{loc.area}</strong>
        </>
    );
};

export const searchTextDescr = (state) => {
    return state.searchText === null || state.searchText === "" ? null : (
        <>
            {"αναζήτηση κειμένου για "}
            <strong>{state.searchText}</strong>
        </>
    );
};
