import React from "react";
import { FormGroup, Label, Input } from "reactstrap";

function TextFormField({
  name,
  title,
  text,
  setTextFn,
  placeholder = "",
  disabled = false,
  type = "text",
  rows = 1, // if type=="textarea", set rows
}) {
  return (
    <FormGroup>
      <Label for={name} size="sm">
        {title}
      </Label>
      <Input
        type={type}
        name={name}
        id={name}
        bsSize="sm"
        disabled={disabled}
        placeholder={placeholder}
        value={text}
        rows={rows}
        onChange={(e) => setTextFn(e.target.value)}
      />
    </FormGroup>
  );
}

export default TextFormField;
