import { useContext, useEffect, useState } from "react";
import { useWebsockets } from "./useWebsockets";
import { Context } from "./Store";

export const useAnnouncements = () => {
  const [state] = useContext(Context);
  const [announcements, setAnnouncements] = useState([]);
  const [unread, setUnread] = useState(0);
  const [connected, send] = useWebsockets();

  const processAnnouncements = (ann) => {
    const alreadyRead =
      localStorage.getItem("read_announcements")?.split(",") || [];
    const annotated = ann?.map((item) => {
      return { ...item, read: alreadyRead.indexOf(item.publishing.id) !== -1 };
    }) || [];
    const unread = annotated.filter((item) => !item.read) || [];
    setAnnouncements(annotated);
    setUnread(unread.length);
  };

  const getAnnouncement = (id) => {
    const result = announcements.filter((item) => item.publishing.id === id);
    return result.length > 0 ? result[0] : null;
  };

  const markAsRead = (id) => {
    const existing = getAnnouncement(id);
    if (existing && !existing.read) {
      const alreadyRead =
        localStorage.getItem("read_announcements")?.split(",") || [];
      const newset = alreadyRead.concat([id]);
      // console.log("read: ", newset);
      localStorage.setItem("read_announcements", newset.join(","));
      readAnnouncements();
    }
  };

  const markAsUnread = (id) => {
    const existing = getAnnouncement(id);
    if (existing && existing.read) {
      let alreadyRead =
        localStorage.getItem("read_announcements")?.split(",") || [];
      const index = alreadyRead.indexOf(id);
      if (index !== -1) {
        // console.log("unread: ", id);
        alreadyRead = alreadyRead.filter((item) => item !== id);
        localStorage.setItem("read_announcements", alreadyRead.join(","));
        readAnnouncements();
      }
    }
  };

  const readAnnouncements = () => {
    if (connected) {
      send(
        "announcements",
        null,
        (msg) => processAnnouncements(msg?.payload),
      );
    }
  };

  useEffect(
    () => {
      readAnnouncements();
      const timer = setInterval(readAnnouncements, 5000);
      return () => clearInterval(timer);
    },
    [connected, send, state.access_token, unread],
  );

  return [announcements, unread, markAsRead, markAsUnread];
};
