import writeXlsxFile from "write-excel-file";
import { createExcelHeader } from "../../export/common";

export const exportExcel = async (filename, state, statistics, records) => {
    const columns = [
        { width: 28 },
        { width: 13 },
        { width: 15 },
        { width: 15 },
        { width: 15 },
        { width: 6 },
        { width: 10 },
        { width: 12 },
        { width: 12 },
        { width: 28 },
        { width: 13 },
        { width: 15 },
        { width: 15 },
        { width: 15 },
        { width: 6 },
        { width: 10 },
        { width: 12 },
        { width: 12 },
    ];
    const topHeader = createExcelHeader(state, statistics);

    const header = [
        { value: "Αντιπρόσωπος", fontWeight: "bold" },
        { value: "ΑΦΜ", fontWeight: "bold" },
        { value: "Περιοχή", fontWeight: "bold" },
        { value: "Πόλη", fontWeight: "bold" },
        { value: "Νομός", fontWeight: "bold" },
        { value: "Τ.Κ.", fontWeight: "bold" },
        { value: "Συσκευές", fontWeight: "bold" },
        { value: "Συναλλαγές", fontWeight: "bold" },
        { value: "Προμήθεια", fontWeight: "bold" },
        { value: "Έμπορος", fontWeight: "bold" },
        { value: "ΑΦΜ", fontWeight: "bold" },
        { value: "Περιοχή", fontWeight: "bold" },
        { value: "Πόλη", fontWeight: "bold" },
        { value: "Νομός", fontWeight: "bold" },
        { value: "Τ.Κ.", fontWeight: "bold" },
        { value: "Σειριακός", fontWeight: "bold" },
        { value: "Συναλλαγές", fontWeight: "bold" },
        { value: "Προμήθεια", fontWeight: "bold" },
    ];

    let data = [];
    for (let x = 0; x < records.length; ++x) {
        const item = records[x];
        data.push([
            { type: String, value: item.company },
            { type: String, value: item.tin },
            { type: String, value: item.area },
            { type: String, value: item.city },
            { type: String, value: item.nomos },
            { type: String, value: item.zipCode },
            { type: Number, value: item.totalDevices },
            { type: Number, value: item.totalTransactions },
            { type: Number, value: item.commission },
            { type: String, value: "" },
            { type: String, value: "" },
            { type: String, value: "" },
            { type: String, value: "" },
            { type: String, value: "" },
            { type: String, value: "" },
            { type: String, value: "" },
            { type: String, value: "" },
            { type: String, value: "" },
        ]);

        for (let y = 0; y < item.devices.length; ++y) {
            const dev = item.devices[y];
            data.push([
                { type: String, value: "" },
                { type: String, value: "" },
                { type: String, value: "" },
                { type: String, value: "" },
                { type: String, value: "" },
                { type: String, value: "" },
                { type: String, value: "" },
                { type: String, value: "" },
                { type: String, value: "" },
                { type: String, value: dev.company },
                { type: String, value: dev.tin },
                { type: String, value: dev.area },
                { type: String, value: dev.city },
                { type: String, value: dev.nomos },
                { type: String, value: dev.zipCode },
                { type: String, value: dev.deviceSerialNo },
                { type: Number, value: dev.totalTransactions },
                { type: Number, value: dev.commission },
            ]);
        }
    }

    const file = await writeXlsxFile([...topHeader, header, ...data], {
        columns,
        fileName: filename,
    });
    return file;
};
