import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../../Store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { sortObjectArray } from "../../../utils";
import { Button, Container, Table } from "reactstrap";
import Header from "./header";
import Row from "./row";
import Cards from "./Cards";
import Pagination from "../../Pagination/Pagination";
import { usePagination } from "../../Pagination/usePagination";
import { exportExcel } from "./exporter";
import Spinner from "../../../Spinner";
// import LineTotals from "../../charts/LineTotals";
import Filters from "../../filter/Filters";
import { useApiCall } from "../../../useApiCall";
import "../transactions.css";

function List({ posit = false }) {
    const [state] = useContext(Context);
    const [userDetails, setUserDetails] = useState({
        database: "",
        userId: "",
        records: [],
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(15);
    const [sortedField, setSortedField] = useState("");
    const [transactions, setTransactions] = useState(null);
    const [originalTransactions, setOriginalTransactions] = useState(null);
    const pagination = usePagination({
        currentPage,
        pageSize,
        records: transactions?.records,
    });
    const getTotalTransactions = useApiCall("/total_transactions");

    const sort = (key, asc) => {
        setSortedField(key);
        setTransactions({
            ...transactions,
            records: sortObjectArray(transactions?.records, key, asc),
        });
    };

    useEffect(() => {
        const text = state.searchText;
        if (text !== null && text !== "") {
            if (text && text !== "") {
                const matches = originalTransactions?.records?.filter(
                    (item) => {
                        return (
                            item.company?.includes(text) ||
                            item.city?.includes(text) ||
                            item.area?.includes(text) ||
                            item.tin?.includes(text) ||
                            item.deviceSerialNo?.includes(text)
                        );
                    }
                );
                setTransactions({ ...originalTransactions, records: matches });
            }
        } else {
            setTransactions(originalTransactions);
        }
    }, [setTransactions, state.searchText, originalTransactions]);

    const expandRow = (row) => {
        if (
            row.database !== "" &&
            row.userId !== "" &&
            userDetails.userId !== row.userId
        ) {
            getTotalTransactions(
                JSON.stringify({
                    ...state.filters,
                    user: { userIds: state.userIds },
                    database: row.database,
                    bank: row.bank,
                    posit: posit,
                }),
                "/" + row.userId
            ).then((resp) => setUserDetails(resp));
        } else {
            setUserDetails({ userId: "", records: [] });
        }
    };

    useEffect(() => {
        const abort = new AbortController();

        setTransactions(null);
        getTotalTransactions(
            JSON.stringify(
                {
                    ...state.filters,
                    user: { userIds: state.userIds },
                    database: state.database,
                    bank: state.bank,
                    posit: posit,
                }),
            "",
            { signal: abort.signal }
        ).then((resp) => {
            setTransactions(resp);
            setOriginalTransactions(resp);
        });

        return () => abort.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        setOriginalTransactions,
        setTransactions,
        state.filters,
        state.userIds,
        state.access_token,
        state.database,
        posit,
    ]);

    return (
        <Container fluid>
            <h2 className="page-header">
                Συνολικές συναλλαγές {posit ? "καρτοκινητής" : "πληρωμών"}
            </h2>

            <Cards transactions={transactions} />

            <Filters
                statistics={transactions?.statistics}
                transactionsVisible={true}
                locationVisible={true}
                searchVisible={true}
            />

            {!transactions && <Spinner />}
            {transactions?.records?.length && (
                <>
                    {/* <LineTotals transactions={transactions} /> */}

                    <Table size="sm" striped hover responsive>
                        <Header sortedField={sortedField} doSort={sort} />
                        <tbody>
                            {pagination.records?.map((trans) => {
                                return (
                                    <Row
                                        row={trans}
                                        key={`${trans.database}.${trans.userId}`}
                                        expandFn={expandRow}
                                        detailsFor={userDetails}
                                    />
                                );
                            })}
                        </tbody>
                    </Table>

                    <div className="exporters-container">
                        <Button
                            size="sm"
                            onClick={() =>
                                exportExcel(
                                    "TotalTransactions.xlsx",
                                    state,
                                    transactions?.statistics,
                                    transactions?.records
                                )
                            }
                        >
                            <FontAwesomeIcon icon="save" /> Εξαγωγή σε Excel
                        </Button>
                    </div>

                    <Pagination
                        pagination={pagination}
                        currentPage={currentPage}
                        pageSize={pageSize}
                        onPageChange={setCurrentPage}
                        setPageSize={setPageSize}
                    />
                </>
            )}
        </Container>
    );
}

export default List;
