import React, { useState } from "react";
import { Button, Container, Form } from "reactstrap";
import TextFormField from "./components/filter/TextFormField";
import "./App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useApiCall } from "./useApiCall";

function ContactUs() {
    const [comments, setComments] = useState("");
    const sendMail = useApiCall("/contact", "POST");

    const doSendMail = (e) => {
        e.preventDefault();
        sendMail(JSON.stringify({
            body: comments,
        }));
    };

    return <Container fluid>
        <h2 className="page-header">
            Επικοινωνήστε μαζί μας
        </h2>

        <div className="contact-container">
            <p>
                Μοιραστείτε μαζί μας την γνώμη σας γι' αυτή την εφαρμογή καθώς και τα
                σχόλιά σας για να γίνει ακόμα καλύτερη καλύπτοντας τις δικές σας
                ανάγκες!
            </p>

            <Form className="contact-form">
                <TextFormField
                    name="comments"
                    title="Σχόλια"
                    type="textarea"
                    rows={20}
                    text={comments}
                    setTextFn={setComments}
                    placeholder="Αφήστε τα σχόλιά σας εδώ" />
                <div className="contact-buttons">
                    <Button type="submit" onClick={doSendMail}>
                        <FontAwesomeIcon icon="envelope" />
                        {" "}Αποστολή
                    </Button>
                </div>
            </Form>
        </div>

    </Container>;
}

export default ContactUs;
