export const createLightExcelHeader = (state, statistics) => {
    let dates = statistics?.date;
    if (!dates?.start || !dates?.end) {
        if (state.filters.date.range !== 0) {
            dates = state.presetDates[state.filters.date.range];
        } else {
            dates = {
                start: state.filters.date.custom.start,
                end: state.filters.date.custom.end,
            };
        }
    }

    let headers = [
        [
            { value: "Περίοδος:" },
            {
                value: new Date(dates.start).toLocaleDateString("el-GR") + " έως " + new Date(dates.end).toLocaleDateString("el-GR"),
            },
        ],
    ];

    headers.push([{}]);

    return headers;
}

export const createExcelHeader = (state, statistics) => {
    let dates = statistics?.date;
    if (!dates?.start || !dates?.end) {
        if (state.filters.date.range !== 0) {
            dates = state.presetDates[state.filters.date.range];
        } else {
            dates = {
                start: state.filters.date.custom.start,
                end: state.filters.date.custom.end,
            };
        }
    }
    let minTrans = `${state.filters.transactions.min}`;
    let maxTrans = `${state.filters.transactions.max}`;

    if (maxTrans === "-1") {
        maxTrans = "Απεριόριστος";
    }

    let headers = [
        [{ value: "Φίλτρα", fontWeight: "bold" }],
        [
            { value: "Πηγή:" },
            {
                value: state.database ? state.database : "Όλες οι πηγές",
            },
        ],
        [
            { value: "Από:" },
            {
                value: new Date(dates.start).toLocaleDateString("el-GR"),
            },
        ],
        [
            { value: "Έως:" },
            {
                value: new Date(dates.end).toLocaleDateString("el-GR"),
            },
        ],
        [{ value: "Ελάχιστος αρ. συναλλαγών:" }, { value: minTrans }],
        [{ value: "Μέγιστος αρ. συναλλαγών:" }, { value: maxTrans }],
    ];

    if (state.filters.location.area) {
        headers.push([
            { value: "Περιοχή:" },
            { value: state.filters.location.area },
        ]);
    }

    if (state.filters.location.city) {
        headers.push([
            { value: "Πόλη:" },
            { value: state.filters.location.city },
        ]);
    }

    if (state.filters.location.nomos) {
        headers.push([
            { value: "Νομός:" },
            { value: state.filters.location.nomos },
        ]);
    }

    if (state.filters.location.zipCode) {
        headers.push([
            { value: "Τ.Κ.:" },
            { value: state.filters.location.zipCode },
        ]);
    }

    headers.push([{}]);

    return headers;
};
