import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container } from "reactstrap";
import "./spinner.css";

const Spinner = ({ message = "Παρακαλώ περιμένετε...", showSpinner = true }) => {
    return (
        <Container fluid className="spinner-container">
            {showSpinner && <FontAwesomeIcon icon="spinner" spin size="5x" />}
            <div>{message}</div>
        </Container>
    );
};

Spinner.propTypes = {
    message: PropTypes.string,
    showSpinner: PropTypes.bool,
};

export default Spinner;
