import React from "react";
import NumberCard from "../../cards/NumberCard";

function Cards({ transactions }) {
    const sumTransactions = transactions?.statistics?.transactionsSum || 0;
    const sumCommissions =
        transactions?.statistics?.paymentMethods?.total?.commissions?.sum || 0;
    const percent = (v, max) => {
        const len = max || 0;
        if (len === 0) return "";
        const pct = v / len;
        if (isNaN(pct)) return "";
        return (pct * 100).toFixed(1) + "%";
    };

    return (
        <>
            <div className="cards-container">
                <NumberCard
                    icon="building"
                    number={transactions?.records?.length || 0}
                    title="Σύνολο εγγραφών"
                />
                <NumberCard
                    icon="receipt"
                    number={transactions?.statistics?.transactionsSum || 0}
                    title="Συνολικές συναλλαγές"
                />
                <NumberCard
                    icon="receipt"
                    number={(
                        transactions?.statistics?.paymentMethods?.total
                            ?.commissions.sum || 0
                    ).toFixed(1)}
                    title="Σύνολο προμηθειών"
                />
            </div>
            <div className="cards-container">
                <NumberCard
                    icon="money-bill-wave"
                    number={
                        transactions?.statistics?.paymentMethods?.total
                            ?.transactions?.cash || 0
                    }
                    title="Συναλλαγές με μετρητά"
                    subtitle={percent(
                        transactions?.statistics?.paymentMethods?.total
                            ?.transactions?.cash || 0,
                        sumTransactions
                    )}
                />
                <NumberCard
                    icon="credit-card"
                    number={
                        transactions?.statistics?.paymentMethods?.total
                            ?.transactions?.card || 0
                    }
                    title="Συναλλαγές με κάρτα"
                    subtitle={percent(
                        transactions?.statistics?.paymentMethods?.total
                            ?.transactions?.card || 0,
                        sumTransactions
                    )}
                />
                <NumberCard
                    icon="money-bill-wave"
                    number={
                        transactions?.statistics?.paymentMethods?.total?.commissions?.cash.toFixed(
                            1
                        ) || 0.0
                    }
                    title="Προμήθειες με μετρητά"
                    subtitle={percent(
                        transactions?.statistics?.paymentMethods?.total
                            ?.commissions?.cash || 0,
                        sumCommissions
                    )}
                />
                <NumberCard
                    icon="credit-card"
                    number={
                        transactions?.statistics?.paymentMethods?.total?.commissions?.card.toFixed(
                            1
                        ) || 0.0
                    }
                    title="Προμήθειες με κάρτα"
                    subtitle={percent(
                        transactions?.statistics?.paymentMethods?.total
                            ?.commissions?.card || 0,
                        sumCommissions
                    )}
                />
                <NumberCard
                    icon="credit-card"
                    number={
                        transactions?.statistics?.paymentMethods?.publicSector?.commissions?.sum.toFixed(
                            1
                        ) || 0.0
                    }
                    title="Προμήθειες Δημοσίου"
                    subtitle={percent(
                        transactions?.statistics?.paymentMethods?.publicSector
                            ?.commissions?.sum || 0,
                        sumCommissions
                    )}
                />
                <NumberCard
                    icon="credit-card"
                    number={
                        transactions?.statistics?.paymentMethods?.privateSector?.commissions?.sum.toFixed(
                            1
                        ) || 0.0
                    }
                    title="Προμήθειες Ιδ. Τομέα"
                    subtitle={percent(
                        transactions?.statistics?.paymentMethods?.privateSector
                            ?.commissions?.sum || 0,
                        sumCommissions
                    )}
                />
            </div>
        </>
    );
}

export default Cards;
