import React, { useEffect, useContext, useState } from "react";
import { Context } from "../../Store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Container, Table } from "reactstrap";
import Pagination from "../Pagination/Pagination";
import SortableLink from "../SortableLink";
import SelectObjectDialog from "../dialogs/SelectObjectDialog";
import ConfirmationDialog from "../dialogs/ConfirmationDialog";
import SalesmanAssignmentDialog from "./SalesmanAssignmentDialog";
import Spinner from "../../Spinner";
import { usePagination } from "../Pagination/usePagination";
import { useApiCall } from "../../useApiCall";
import "./EditSalesmen.css";
import { ADD_ALERT } from "../../Reducer";

const EditSalesmen = () => {
  const [state, dispatch] = useContext(Context);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(15);
  const [selected, setSelected] = useState("");
  const [selectedInfo, setSelectedInfo] = useState(null);
  const [sortedField, setSortedField] = useState("");
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [showAddAssignmentDialog, setShowAddAssignmentDialog] = useState(false);
  // const [user, setUser] = useState({});
  const [allUsers, setAllUsers] = useState([]);
  // const [smen, setSmen] = useState([]);
  const [smanInfo, setSmanInfo] = useState(null);
  const [invalidAssignments, setInvalidAssignments] = useState(null);
  const [validatingAssignments, setValidatingAssignments] = useState(false);

  const [showConfirmDelSmanDialog, setShowConfirmDelSmanDialog] =
    useState(false);
  const [showConfirmDelAssgnDialog, setShowConfirmDelAssgnDialog] =
    useState(false);

  // const userInfo = useApiCall("/user/info", "POST");
  const userSetSalesman = useApiCall("/user/set_salesman", "POST");
  const getAllUsers = useApiCall("/user", "POST");
  const getSalesmanInfo = useApiCall("/salesman/info", "POST");
  const validateSalesmanAssignments = useApiCall("/salesman/validate", "POST");
  const addEditSalesmanAssignment = useApiCall("/salesman/assign", "POST");

  const pagination = usePagination({
    currentPage: currentPage,
    pageSize: pageSize,
    records: state.salesmen,
  });

  const sort = (key, /* asc */) => {
    setSortedField(key);
    // setSmen(sortObjectArray(smen ? smen : [], key, asc));
  };

  const addUser = (id) => {
    setShowAddDialog(false);
    userSetSalesman(JSON.stringify({ id: id, isSalesman: true })).then(() => {
      // allSalesmen().then((resp) => {
      //   setSmen(resp);
      //   dispatch({ type: SET_SALESMEN, payload: resp });
      // });
    });
  };

  const deleteUser = (id) => {
    setShowAddDialog(false);
    userSetSalesman(JSON.stringify({ id: id, isSalesman: false })).then(() => {
      // allSalesmen().then((resp) => {
      //   setSmen(resp);
      //   setSmanInfo(null);
      //   dispatch({ type: SET_SALESMEN, payload: resp });
      // });
    });
  };

  const addEditAssignment = (id, a = "", c = "", n = "", zc = "") => {
    setShowAddAssignmentDialog(false);
    addEditSalesmanAssignment(
      JSON.stringify({
        id: id ? id : "",
        userId: selected,
        area: a,
        city: c,
        nomos: n,
        postalCode: zc,
      })
    ).then(() =>
      getSalesmanInfo(JSON.stringify({ id: selected })).then((resp) =>
        setSmanInfo(resp || [])
      )
    );
  };

  const selectUser = async (id) => {
    setSelected(id);
    setSelectedInfo(null);
    setSmanInfo(null);
    getSalesmanInfo(JSON.stringify({ id: id })).then((resp) => {
      setSmanInfo(resp || []);
    });
  };

  const confirmDialogResponse = (btn) => {
    if (showConfirmDelSmanDialog) {
      // delete salesman
      setShowConfirmDelSmanDialog(false);
      if (btn === "Ναι") {
        deleteUser(selected);
      }
    } else if (showConfirmDelAssgnDialog) {
      // delete assignment
      setShowConfirmDelAssgnDialog(false);
      if (btn === "Ναι") {
        addEditAssignment(selectedInfo.id); // empty params mean "delete"
      }
    }
  };

  const alertAssignmentsForSuccess = () => {
    dispatch({
      type: ADD_ALERT,
      payload: {
        title: "Χωρίς λάθη!",
        message:
          "Δεν βρέθηκαν λάθη στις αναθέσεις! Όλες οι περιοχές, πόλεις και νομοί αντιστοιχούν σε υπάρχουσες συσκευές και εμπόρους.",
        color: "success",
        timeout: 5000,
      },
    });
  };

  const alertAssignmentsForErrors = (resp) => {
    const showAlert = (what) => {
      dispatch({
        type: ADD_ALERT,
        payload: {
          title: what,
          message: `Υπάρχουν λάθος ${what.toLocaleLowerCase()} στις αναθέσεις που ΔΕΝ αντιστοιχούν σε καμμία συσκευή ή έμπορο...`,
          color: "danger",
          timeout: 5000,
        },
      });
    };
    if (resp.areas.length) {
      showAlert("Περιοχές");
    }
    if (resp.cities.length) {
      showAlert("Πόλεις");
    }
    if (resp.nomoi.length) {
      showAlert("Νομοί");
    }
  };

  const validateAssignments = () => {
    setValidatingAssignments(true);
    validateSalesmanAssignments(JSON.stringify({ id: selected }))
      .then((resp) => {
        setInvalidAssignments(resp);
        if (resp.areas.length || resp.cities.length || resp.nomoi.length) {
          alertAssignmentsForErrors(resp);
        } else {
          alertAssignmentsForSuccess();
        }
      })
      .finally(() => setValidatingAssignments(false));
  };

  //   useEffect(() => {
  //     userInfo().then((resp) => setUser(resp));
  //   }, [setUser]);

  useEffect(() => {
    const abort = new AbortController();
    getAllUsers(null, "", { signal: abort.signal }).then((resp) =>
      setAllUsers(resp)
    );
    return () => abort.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setAllUsers, state.access_token]);

  // useEffect(() => {
  //   setSmen(state.salesmen);
  // }, [setSmen, state.access_token, state.salesmen]);

  /*
      User ID: {user?.id}
      <br />
      User name: {user?.username}
      <br />
      Is admin: {user?.isAdmin ? "yes" : "no"}
      <br />
      Is salesman: {user?.isSalesman ? "yes" : "no"}
      <br />
   */

  return (
    <Container fluid>
      <h2 className="page-header">Διαχείριση πωλητών</h2>
      <div className="smen-container">
        <div className="smen-list">
          {state.salesmen?.length === 0 && <Spinner />}
          {state.salesmen?.length > 0 && (
            <>
              <Table size="sm" striped hover responsive>
                <SalesmenHeader sortedField={sortedField} doSort={sort} />
                <tbody>
                  {pagination.records?.map((v) => {
                    return (
                      <SalesmenRow
                        key={v.id}
                        row={v}
                        selected={selected}
                        setSelectedFn={selectUser}
                      />
                    );
                  })}
                </tbody>
              </Table>
              <Pagination
                pagination={pagination}
                currentPage={currentPage}
                pageSize={pageSize}
                onPageChange={setCurrentPage}
                setPageSize={setPageSize}
              />
              <div className="buttons-container">
                <Button
                  color="primary"
                  size="sm"
                  onClick={() => setShowAddDialog(true)}
                >
                  <FontAwesomeIcon icon="plus-square" /> Νέος πωλητής
                </Button>
                <Button
                  size="sm"
                  color="danger"
                  disabled={!selected?.length}
                  onClick={() => {
                    setShowConfirmDelSmanDialog(true);
                  }}
                >
                  <FontAwesomeIcon icon="trash-alt" /> Διαγραφή
                </Button>
              </div>
            </>
          )}
        </div>
        <div className="smen-details">
          <h6>Γεωγραφικά σημεία ευθύνης πωλητή</h6>
          {selected === "" && (
            <span className="no-record-selected">
              Επιλέξτε έναν πωλητή στην λίστα αριστερά για να δείτε εδώ τις
              περιοχές ευθύνης του&hellip;
            </span>
          )}
          {selected !== "" && !smanInfo && <Spinner />}
          {selected !== "" && smanInfo && (
            <div className="assignments-container">
              <Assignments
                title="Περιοχές"
                records={smanInfo}
                fieldName="area"
                selectedInfo={selectedInfo}
                setSelectedInfoFn={setSelectedInfo}
                invalidEntries={invalidAssignments?.areas}
              />
              <Assignments
                title="Πόλεις"
                records={smanInfo}
                fieldName="city"
                selectedInfo={selectedInfo}
                setSelectedInfoFn={setSelectedInfo}
                invalidEntries={invalidAssignments?.cities}
              />
              <Assignments
                title="Νομοί"
                records={smanInfo}
                fieldName="nomos"
                selectedInfo={selectedInfo}
                setSelectedInfoFn={setSelectedInfo}
                invalidEntries={invalidAssignments?.nomoi}
              />
              <Assignments
                title="Ταχ. κωδικοί"
                records={smanInfo}
                fieldName="postalCode"
                selectedInfo={selectedInfo}
                setSelectedInfoFn={setSelectedInfo}
              />
            </div>
          )}
          <div className="buttons-container">
            <Button
              color="success"
              size="sm"
              disabled={!selected}
              onClick={validateAssignments}
            >
              <FontAwesomeIcon
                icon={validatingAssignments ? "spinner" : "check"}
                spin={validatingAssignments}
              />{" "}
              Επαλήθευση αναθέσεων
            </Button>
            <Button
              color="primary"
              size="sm"
              disabled={!selected?.length}
              onClick={() => {
                setSelectedInfo(null);
                setShowAddAssignmentDialog(true);
              }}
            >
              <FontAwesomeIcon icon="plus-square" /> Νέα
            </Button>
            <Button
              color="secondary"
              size="sm"
              disabled={!selected?.length || !selectedInfo}
              onClick={() => setShowAddAssignmentDialog(true)}
            >
              <FontAwesomeIcon icon="edit" /> Επεξεργασία
            </Button>
            <Button
              size="sm"
              color="danger"
              disabled={!selected?.length || !selectedInfo}
              onClick={() => {
                setShowConfirmDelAssgnDialog(true);
              }}
            >
              <FontAwesomeIcon icon="trash-alt" /> Διαγραφή
            </Button>
          </div>
        </div>
      </div>

      <SalesmanAssignmentDialog
        isOpen={showAddAssignmentDialog}
        closeFn={() => setShowAddAssignmentDialog(false)}
        saveFn={addEditAssignment}
        record={selectedInfo}
      />

      <SelectObjectDialog
        isOpen={showAddDialog}
        closeFn={() => setShowAddDialog(false)}
        selectFn={addUser}
        records={allUsers}
        displayFields={["fullName", "lastName", "firstName", "email"]}
        headerNames={["Επωνυμία", "Επίθετο", "Όνομα", "E-Mail"]}
        valueField="id"
        disableField="isSalesman"
        disableOnValue={true}
        title="Επιλογή χρήστη"
      />

      <ConfirmationDialog
        isOpen={showConfirmDelSmanDialog || showConfirmDelAssgnDialog}
        msg="Είστε σίγουροι ότι θέλετε να διαγράψετε αυτή την εγγραφή; Δεν θα μπορέσετε να αναιρέσετε αυτή την κίνηση!"
        closeFn={confirmDialogResponse}
      />
    </Container>
  );
};

const SalesmenHeader = ({ sortedField, doSort }) => {
  return (
    <thead>
      <tr>
        <th>
          <SortableLink
            title="Επωνυμία"
            sortField="fullName"
            sortedField={sortedField}
            doSort={doSort}
          />
        </th>
      </tr>
    </thead>
  );
};

const SalesmenRow = ({ row, selected, setSelectedFn }) => {
  return (
    <>
      <tr
        className={selected === row.id ? "selected-sman " : ""}
        onClick={() => {
          setSelectedFn(row.id);
        }}
      >
        <td>{row.fullName}</td>
      </tr>
    </>
  );
};

const Assignments = ({
  title,
  records,
  fieldName,
  selectedInfo,
  setSelectedInfoFn,
  invalidEntries,
}) => {
  return (
    <div className="assignment-column">
      {title}
      <div className="assignment-column-content">
        {records?.map((v) => {
          const selected = selectedInfo?.id === v.id;
          const invalid = invalidEntries
            ? invalidEntries.indexOf(v[fieldName]) !== -1
            : false;
          return (
            <div
              key={v.id}
              onClick={() => {
                setSelectedInfoFn(selected ? null : v);
              }}
              className={
                "assignment-record " +
                (selected ? "selected-sman " : "") +
                (invalid ? " invalid" : "")
              }
            >
              {v[fieldName]}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default EditSalesmen;
