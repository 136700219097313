import React from "react";
import { Card, CardBody, CardTitle, CardSubtitle, Container } from "reactstrap";

function List() {
  return (
    <Container fluid>
      <h2 className="page-header">Συναλλαγές και αξίες ανά τρόπο πληρωμής</h2>
      <Card color="warning">
        <CardBody>
          <CardTitle>Προσωρινά απενεργοποιημένη αναφορά</CardTitle>
          <CardSubtitle className="mb-2 text-muted">
            Ζητούμε την κατανόησή σας...
          </CardSubtitle>
        </CardBody>
      </Card>
    </Container>
  );
}

// function DisabledList() {
//     const [state] = useContext(Context);
//     const [expandedUser, setExpandedUser] = useState("");
//     const [currentPage, setCurrentPage] = useState(1);
//     const [pageSize, setPageSize] = useState(15);
//     const [sortedField, setSortedField] = useState("");
//     const [sortedDirection, setSortedDirection] = useState(null);
//     const [transactions, setTransactions] = useState(null);
//     const [originalTransactions, setOriginalTransactions] = useState(null);
//     const pagination = usePagination({
//         currentPage,
//         pageSize,
//         records: transactions?.records,
//     });
//     const getTotalTransactionsValuesPerPaymentMethod = useApiCall(
//         "/total_transactions_values_per_payment_method"
//     );

//     useEffect(() => {
//         getTotalTransactionsValuesPerPaymentMethod(
//             JSON.stringify(state.filters)
//         ).then((resp) => {
//             setTransactions(resp);
//             setOriginalTransactions(resp);
//         });
//     }, [
//         setOriginalTransactions,
//         setTransactions,
//         state.filters,
//         state.access_token,
//     ]);

//     const expandRow = (id) => setExpandedUser(id);

//     useEffect(() => {
//         const text = state.searchText;
//         if (text !== null && text !== "") {
//             // search in Users and devices
//             const matches = originalTransactions?.records.filter((item) => {
//                 if (item.company?.includes(text) || item.tin?.includes(text)) {
//                     return true;
//                 }
//                 if (
//                     item.devices?.filter((det) => {
//                         return (
//                             det.company?.includes(text) ||
//                             det.tin?.includes(text) ||
//                             det.deviceSerialNo?.includes(text)
//                         );
//                     }).length > 0
//                 ) {
//                     return true;
//                 }
//                 return false;
//             });
//             // expand first User if match was in devices
//             if (matches?.length > 0) {
//                 if (
//                     !matches[0].company?.includes(text) &&
//                     !matches[0].tin?.includes(text)
//                 ) {
//                     setExpandedUser(matches[0].UserId);
//                 }
//             }
//             setTransactions({ ...originalTransactions, records: matches });
//         } else {
//             setTransactions(originalTransactions);
//             setExpandedUser("");
//         }
//         sort(setSortedField, sortedDirection);
//     }, [state.filters, state.searchText, originalTransactions]);

//     const sort = (key, asc) => {
//         if (key !== "" && asc !== null) {
//             setSortedField(key);
//             setSortedDirection(asc);
//             setTransactions({
//                 ...transactions,
//                 records: sortObjectArray(transactions?.records, key, asc),
//             });
//         }
//     };

//     if (transactions === null) {
//         return <Spinner />;
//     }

//     return (
//         <Container fluid>
//             <h2 className="page-header">
//                 Συναλλαγές και αξίες ανά τρόπο πληρωμής
//             </h2>

//             <Cards transactions={transactions} />

//             <Filters
//                 statistics={transactions?.statistics}
//                 transactionsVisible={true}
//                 locationVisible={true}
//                 searchVisible={true}
//             />

//             <Container fluid="sm">
//                 <Row>
//                     <Col sm={2} />
//                     <Col sm={8}>
//                         <Table
//                             className="table-small"
//                             size="sm"
//                             striped
//                             hover
//                             responsive
//                         >
//                             <ListHeader
//                                 sortedField={sortedField}
//                                 doSort={sort}
//                             />
//                             <tbody>
//                                 {pagination.records?.map((row) => {
//                                     return (
//                                         <ListRow
//                                             key={row.originalIndex}
//                                             data={row}
//                                             expandFn={expandRow}
//                                             isExpanded={
//                                                 row.userId === expandedUser
//                                             }
//                                         />
//                                     );
//                                 })}
//                             </tbody>
//                         </Table>
//                     </Col>
//                 </Row>
//             </Container>

//             <div className="exporters-container">
//                 <Button
//                     size="sm"
//                     onClick={() =>
//                         ExportToFile(
//                             "TotalTransactionsPerUser.csv",
//                             "application/csv",
//                             exportCSV(transactions?.records)
//                         )
//                     }
//                 >
//                     <FontAwesomeIcon icon="save" /> Εξαγωγή CSV
//                 </Button>
//             </div>

//             <Pagination
//                 pagination={pagination}
//                 currentPage={currentPage}
//                 pageSize={pageSize}
//                 onPageChange={setCurrentPage}
//                 setPageSize={setPageSize}
//             />
//         </Container>
//     );
// }

export default List;
