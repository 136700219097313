export const sortObjectArray = (arr, key, ascending) => {
    if (arr === undefined) {
        return [];
    }

    const ascOp = (a, b) => (a > b ? 1 : -1);
    const descOp = (a, b) => (a < b ? 1 : -1);
    return []
        .concat(arr)
        .sort((a, b) =>
            ascending ? ascOp(a[key], b[key]) : descOp(a[key], b[key])
        );
};

export const dateToString = (d) => {
    return d.toISOString().split('T')[0].replaceAll('-', '')
}
