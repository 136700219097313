import React, { useContext, useState, useEffect } from "react";
import { Context } from "../Store";
import { Container, Table, Row, Col, Input } from "reactstrap";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { usePagination } from "./Pagination/usePagination";
import Pagination from "./Pagination/Pagination";
import Spinner from "../Spinner";
import Filters from "./filter/Filters";
import { useApiCall } from "../useApiCall";
import "./errors.css";

function Errors() {
    const [state] = useContext(Context);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(15);
    const [errors, setErrors] = useState(null);
    const [includeNoTrans, setIncludeNoTrans] = useState(false);
    const [details, setDetails] = useState({});
    const [detailsOpen, setDetailsOpen] = useState(false);
    const pagination = usePagination({
        currentPage,
        pageSize,
        records: errors?.records,
    });
    const getErrors = useApiCall("/errors");
    const getDevice = useApiCall("/device");

    const showDeviceDetails = (db, id) => {
        getDevice(
            JSON.stringify({
                id: id,
                database: db,
            })
        ).then((resp) => {
            setDetails(resp);
            setDetailsOpen(true);
        });
    };

    const toggleDetailsFn = () => setDetailsOpen(false);

    useEffect(() => {
        const abort = new AbortController();
        setErrors(null);
        getErrors(
            JSON.stringify(
                {
                    ...state.filters,
                    database: state.database,
                    includeNoTransactions: includeNoTrans,
                },
                "",
                { signal: abort.signal }
            )
        ).then((resp) => setErrors(resp));
        return () => abort.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        setErrors,
        includeNoTrans,
        state.filters,
        state.access_token,
        state.database,
    ]);

    return (
        <Container fluid>
            <h2 className="page-header">Προβλήματα κατά την φόρτωση</h2>

            <Filters
                statistics={errors?.statistics}
                transactionsVisible={false}
                locationVisible={false}
                searchVisible={false}
            />

            <div className="errors-container">
                <Input
                    type="checkbox"
                    checked={includeNoTrans}
                    onChange={(e) => setIncludeNoTrans(e.target.checked)}
                />
                &nbsp;Εμφάνιση συσκευών χωρίς συναλλαγές
            </div>

            {!errors && <Spinner />}
            {errors && (
                <>
                    <Container fluid="sm">
                        <Row style={{ minHeight: 400 }}>
                            <Col>
                                <Table
                                    className="table-centered table-condensed"
                                    size="sm"
                                    striped
                                    hover
                                    responsive
                                >
                                    <thead>
                                        <tr className="errors-row link">
                                            <td>Πηγή</td>
                                            <td>Σειριακός</td>
                                            <td>Ημ/νία κλήσης</td>
                                            <td>Αφορά την</td>
                                            <td>Βαρύτητα</td>
                                            <td>Κωδικός</td>
                                            <td>Κατηγορία</td>
                                            <td>Περιγραφή</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {pagination.records?.map((row) => {
                                            let e = {}
                                            try {
                                                e = JSON.parse(row.message);
                                            } catch (_) {
                                                e = {
                                                    sev: "Error",
                                                    code: "",
                                                    cat: "",
                                                    desc: row.message,
                                                };
                                            }
                                            return (
                                                <tr
                                                    key={row.originalIndex}
                                                    className="errors-row no-wrap"
                                                    onClick={() =>
                                                        showDeviceDetails(
                                                            row.database,
                                                            row.deviceId
                                                        )
                                                    }
                                                >
                                                    <td>{row.database}</td>
                                                    <td className="serialno">{row.deviceSerialNo}</td>
                                                    <td>{row.stamp}</td>
                                                    <td>{row.requestDate}</td>
                                                    <td>{e.sev}</td>
                                                    <td>{e.code}</td>
                                                    <td>{e.cat}</td>
                                                    <td>{e.desc}</td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Container>

                    <Pagination
                        pagination={pagination}
                        currentPage={currentPage}
                        pageSize={pageSize}
                        onPageChange={setCurrentPage}
                        setPageSize={setPageSize}
                    />
                </>
            )}
            <Modal isOpen={detailsOpen} toggle={toggleDetailsFn}>
                <ModalHeader toggle={toggleDetailsFn}>
                    <FontAwesomeIcon
                        className="errors-icon"
                        size="2x"
                        icon="exclamation-triangle"
                    />
                    Λεπτομέρειες
                </ModalHeader>
                <ModalBody className="error-details">
                    <Row>
                        <Col md={3}>Έμπορος:</Col>
                        <Col md={9}>{details?.user?.fullName}</Col>
                    </Row>
                    <Row>
                        <Col md={3}>E-mail:</Col>
                        <Col md={9}>{details?.user?.email}</Col>
                    </Row>
                    <Row>
                        <Col md={3}>Σειριακός</Col>
                        <Col md={9}>{details?.device?.serialNo}</Col>
                    </Row>
                    <Row>
                        <Col md={3}>Καρτοκινητή:</Col>
                        <Col md={9}>
                            {details?.device?.posit === "true" ? "Ναι" : "Όχι"}
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="secondary"
                        size="sm"
                        onClick={toggleDetailsFn}
                    >
                        Κλείσιμο
                    </Button>
                </ModalFooter>
            </Modal>
        </Container>
    );
}

export default Errors;
