import React, { useCallback, useContext, useState, useEffect } from "react";
import { Context } from "../../../Store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { sortObjectArray } from "../../../utils";
import { Button, Container, Table } from "reactstrap";
import ListHeader from "./header";
import ListRow from "./row";
import Cards from "./Cards";
import { usePagination } from "../../Pagination/usePagination";
import { exportExcel } from "./exporter";
import Pagination from "../../Pagination/Pagination";
import Spinner from "../../../Spinner";
import Filters from "../../filter/Filters";
import { useApiCall } from "../../../useApiCall";

function List({ posit = false }) {
    const [state] = useContext(Context);
    const [expandedDealer, setExpandedDealer] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(15);
    const [sortedField, setSortedField] = useState("");
    const [sortedDirection, setSortedDirection] = useState(null);
    const [transactions, setTransactions] = useState(null);
    const [originalTransactions, setOriginalTransactions] = useState(null);
    const pagination = usePagination({
        currentPage,
        pageSize,
        records: transactions?.records,
    });
    const getTotalTransactionsPerDealer = useApiCall(
        "/total_transactions_per_dealer"
    );

    useEffect(() => {
        const abort = new AbortController();

        setTransactions(null);
        getTotalTransactionsPerDealer(
            JSON.stringify({
                ...state.filters,
                user: { userIds: state.userIds },
                database: state.database,
                posit: posit,
            },
                "",
                { signal: abort.signal }
            )
        ).then((resp) => {
            setTransactions(resp);
            setOriginalTransactions(resp);
        });

        return () => abort.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        setOriginalTransactions,
        setTransactions,
        state.filters,
        state.userIds,
        state.access_token,
        state.database,
        posit,
    ]);

    const expandRow = (id) => setExpandedDealer(id);

    const sort = useCallback(
        (key, asc) => {
            if (key !== "" && asc !== null) {
                setSortedField(key);
                setSortedDirection(asc);
                setTransactions({
                    ...transactions,
                    records: sortObjectArray(transactions?.records, key, asc),
                });
            }
        },
        [setSortedField, setSortedDirection, setTransactions, transactions]
    );

    useEffect(() => {
        const text = state.searchText;
        if (text !== null && text !== "") {
            // search in dealers and devices
            const matches = originalTransactions?.records.filter((item) => {
                if (item.company?.includes(text) || item.tin?.includes(text)) {
                    return true;
                }
                if (
                    item.devices?.filter((det) => {
                        return (
                            det.company?.includes(text) ||
                            det.tin?.includes(text) ||
                            det.deviceSerialNo?.includes(text)
                        );
                    }).length > 0
                ) {
                    return true;
                }
                return false;
            });
            // expand first dealer if match was in devices
            if (matches?.length > 0) {
                if (
                    !matches[0].company?.includes(text) &&
                    !matches[0].tin?.includes(text)
                ) {
                    setExpandedDealer(matches[0].dealerId);
                }
            }
            setTransactions({ ...originalTransactions, records: matches });
        } else {
            setTransactions(originalTransactions);
            setExpandedDealer("");
        }
        sort(setSortedField, sortedDirection);
    }, [
        setTransactions,
        setExpandedDealer,
        state.filters,
        state.searchText,
        originalTransactions,
        sortedDirection,
        sort,
    ]);

    return (
        <Container fluid>
            <h2 className="page-header">
                Συναλλαγές {posit ? "καρτοκινητής" : "πληρωμών"} ανά αντιπρόσωπο
            </h2>

            <Cards transactions={transactions} />

            <Filters
                statistics={transactions?.statistics}
                transactionsVisible={true}
                locationVisible={true}
                searchVisible={true}
            />

            {!transactions && <Spinner />}
            {transactions?.records?.length && (
                <>
                    <Table size="sm" striped hover responsive>
                        <ListHeader sortedField={sortedField} doSort={sort} />
                        <tbody>
                            {pagination.records?.map((row) => {
                                return (
                                    <ListRow
                                        key={row.originalIndex}
                                        data={row}
                                        expandFn={expandRow}
                                        isExpanded={
                                            row.dealerId === expandedDealer
                                        }
                                    />
                                );
                            })}
                        </tbody>
                    </Table>

                    <div className="exporters-container">
                        <Button
                            size="sm"
                            onClick={() =>
                                exportExcel(
                                    "TotalTransactionsPerDealer.xlsx",
                                    state,
                                    transactions?.statistics,
                                    transactions?.records
                                )
                            }
                        >
                            <FontAwesomeIcon icon="save" /> Εξαγωγή σε Excel
                        </Button>
                    </div>

                    <Pagination
                        pagination={pagination}
                        currentPage={currentPage}
                        pageSize={pageSize}
                        onPageChange={setCurrentPage}
                        setPageSize={setPageSize}
                    />
                </>
            )}
        </Container>
    );
}

export default List;
