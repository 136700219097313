import React from "react";
import {
    ResponsiveContainer,
    RadarChart,
    PolarGrid,
    PolarAngleAxis,
    PolarRadiusAxis,
    Radar,
} from "recharts";
import "./charts.css";

/*
 * This component expects a "data" array containing objects, like this:
 * [
 *   { key: "", value: 0 }
 *   ...
 * ]
 */
function RadarData({ data }) {
    return (
        <>
            {data?.length > 0 && (
                <ResponsiveContainer
                    className="radar-chart"
                    width="100%"
                    height="100%"
                >
                    <RadarChart data={data} cx="50%" cy="50%" outerRadius="80%">
                        <PolarGrid />
                        <PolarAngleAxis dataKey="key" />
                        <PolarRadiusAxis />
                        <Radar
                            dataKey="value"
                            stroke="#8884d8"
                            fill="#8884d8"
                            fillOpacity={0.6}
                        />
                    </RadarChart>
                </ResponsiveContainer>
            )}
        </>
    );
}

export default RadarData;
