import React from "react";
import SortableLink from "../../SortableLink";

function Header({ sortedField, doSort }) {
  return (
    <thead>
      <tr className="transaction-row">
        <th />
        <th>
          <SortableLink
            title="Αντιπρόσωπος"
            sortField="company"
            sortedField={sortedField}
            doSort={doSort}
          />
        </th>
        <th>ΑΦΜ</th>
        <th align="right">
          <SortableLink
            title="Συσκευές"
            sortField="totalDevices"
            sortedField={sortedField}
            doSort={doSort}
          />
        </th>
        <th align="right">
          <SortableLink
            title="Συναλλαγές"
            sortField="totalTransactions"
            sortedField={sortedField}
            doSort={doSort}
          />
        </th>
        <th align="right">
          <SortableLink
            title="Προμήθεια"
            sortField="commission"
            sortedField={sortedField}
            doSort={doSort}
          />
        </th>
      </tr>
    </thead>
  );
}

export default Header;
