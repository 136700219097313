import React from "react";
import { FormGroup, Label, Input } from "reactstrap";

function NumberFormField({ name, title, number, setNumberFn, disabled = false }) {
  return (
    <FormGroup>
      <Label for={name} size="sm">
        {title}
      </Label>
      <Input
        type="number"
        name={name}
        id={name}
        bsSize="sm"
        disabled={disabled}
        value={number}
        onChange={(e) => setNumberFn(parseInt(e.target.value))}
      />
    </FormGroup>
  );
}

export default NumberFormField;
