import writeXlsxFile from "write-excel-file";
import { createExcelHeader } from "../../export/common";

export const exportExcel = async (filename, state, statistics, records) => {
    const columns = [
        { width: 28 },
        { width: 13 },
        { width: 15 },
        { width: 15 },
        { width: 15 },
        { width: 6 },
        { width: 10 },
        { width: 10 },
        { width: 12 },
    ];
    const topHeader = createExcelHeader(state, statistics);

    const header = [
        { value: "Έμπορος", fontWeight: "bold" },
        { value: "ΑΦΜ", fontWeight: "bold" },
        { value: "Περιοχή", fontWeight: "bold" },
        { value: "Πόλη", fontWeight: "bold" },
        { value: "Νομός", fontWeight: "bold" },
        { value: "Τ.Κ.", fontWeight: "bold" },
        { value: "Σειριακός", fontWeight: "bold" },
        { value: "Πληρωμή", fontWeight: "bold" },
        { value: "Συναλλαγές", fontWeight: "bold" },
    ];
    const data = records.map((item) => {
        return [
            { type: String, value: item.company },
            { type: String, value: item.tin },
            { type: String, value: item.area },
            { type: String, value: item.city },
            { type: String, value: item.nomos },
            { type: String, value: item.zipCode },
            { type: String, value: item.deviceSerialNo },
            { type: String, value: item.name },
            { type: Number, value: item.totalTransactions },
        ];
    });
    const file = await writeXlsxFile([...topHeader, header, ...data], {
        columns,
        fileName: filename,
    });
    return file;
};
