import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./card.css";

function NumberCard({ icon, title, subtitle, number }) {
    return (
        <div className="sticker-container">
            <span className="sticker-title">{title}</span>
            <div className="sticker-header">
                <FontAwesomeIcon
                    className="sticker-icon"
                    icon={icon}
                    size="3x"
                />
                <div className="sticker-content">
                    <span className="sticker-number">{number}</span>
                    <span className="sticker-subtitle">{subtitle}</span>
                </div>
            </div>
        </div>
    );
}

export default NumberCard;
