import React from "react";

function Row({ row }) {
    return (
        <>
            <tr
                className="transaction-row"
                id={row.id}
                key={row.id}
            >
                <td data-cell="Ημερομηνία">{new Date(row.timestamp).toLocaleString("el-GR", { dateStyle: 'short', timeStyle: 'medium', hour12: false })}</td>
                <td data-cell="Κατηγορία">{row.category}</td>
                <td data-cell="Οργανισμός">{row.organization}</td>
                <td data-cell="Συσκευή">{row.serialNo}</td>
                <td data-cell="Μέθοδος">{row.method === "CARD" ? "Κάρτα" : "Μετρητά"}</td>
                <td data-cell="Κατάσταση">{row.status}</td>
                <td data-cell="Κωδ. συναλλαγής">{row.clearingRef}</td>
                <td data-cell="Ποσό" align="right">{row.amount.toLocaleString(undefined, { style: 'currency', currency: 'EUR' })}</td>
                <td data-cell="Προμήθεια" align="right">{row.commission.toLocaleString(undefined, { style: 'currency', currency: 'EUR' })}</td>
            </tr>
        </>
    );
}

export default Row;
