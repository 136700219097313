import React, { useState, useContext } from "react";
import { Context } from "../../Store";
import { SET_PAGINATION_PAGE_SIZE } from "../../Reducer";
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";

const PageSizeDropdown = () => {
    const [state, dispatch] = useContext(Context);
    const [pageSizeOpen, setPageSizeOpen] = useState(false);

    const togglePageSize = () => {
        setPageSizeOpen((prevState) => !prevState);
    };

    const setPageSize = (sz) => dispatch({ type: SET_PAGINATION_PAGE_SIZE, payload: sz });

    return (
        <Dropdown size="sm" isOpen={pageSizeOpen} toggle={togglePageSize}>
            <DropdownToggle caret>{state.pageSize}</DropdownToggle>
            <DropdownMenu>
                <DropdownItem onClick={() => setPageSize(10)}>10</DropdownItem>
                <DropdownItem onClick={() => setPageSize(15)}>15</DropdownItem>
                <DropdownItem onClick={() => setPageSize(20)}>20</DropdownItem>
                <DropdownItem onClick={() => setPageSize(50)}>50</DropdownItem>
                <DropdownItem onClick={() => setPageSize(100)}>
                    100
                </DropdownItem>
            </DropdownMenu>
        </Dropdown>
    );
}

export default PageSizeDropdown;
