import React, { useContext, useEffect, useRef, useState } from "react";
import { Context } from "./Store";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import useAuth from "./useAuth";
import { apiAuthentication } from "./useApiCall";
import { Button, Container, Form } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./login.css";
import { ADD_ALERT } from "./Reducer";

const LoginForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();
  const nameRef = useRef();
  const [, dispatch] = useContext(Context);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  // const [rememberme, setRememberMe] = useState(false);
  const [loggingIn, setLoggingIn] = useState(false);

  // redirect to the preferred page OR where we came from after login
  const from = localStorage.getItem("startPage") ||
    location.state?.from?.pathname;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoggingIn(true);
    await apiAuthentication(
      username,
      password,
      // rememberme
    )
      .then((response) => {
        if (response?.error?.length > 0) {
          dispatch({
            type: ADD_ALERT,
            payload: {
              title: "Η σύνδεση απέτυχε...",
              message:
                "Ίσως πληκτρολογήσατε λάθος στοιχεία. Παρακαλώ δοκιμάστε ξανά...",
              color: "danger",
              timeout: 5000,
            },
          });
          auth.signout();
        } else {
          dispatch({
            type: ADD_ALERT,
            payload: {
              title: "Καλώς ήλθατε!",
              message: `Έχετε συνδεθεί επιτυχώς στο σύστημα ως ${username}.`,
              color: "success",
              timeout: 5000,
            },
          });
          auth.signin(response?.access_token, () => {
            navigate(from, { replace: true });
          });
        }
      })
      .catch(() => {
        // e.g. "TypeError: NetworkError when attempting to fetch resource."
        dispatch({
          type: ADD_ALERT,
          payload: {
            title: "Η σύνδεση απέτυχε...",
            message:
              "Υπάρχει κάποιο πρόβλημα με το δίκτυο. Παρακαλώ δοκιμάστε αργότερα...",
            color: "danger",
            timeout: 10000,
          },
        });
        // auth.signout();
      })
      .finally(() => setLoggingIn(false));
  };

  useEffect(() => {
    nameRef?.current?.focus();
  }, [nameRef]);

  return (
    <Container fluid>
      {auth.is_signed_in() && <Navigate replace to={from} />}
      <div className={"login-container"}>
        <div className="logo-container">
          <img src="/assets/Trilia_Logo_Blue.png" alt="logo" />
        </div>
        <Form className="login-form" onSubmit={handleSubmit}>
          <div className="login-body">
            <div className="form-header">
              <FontAwesomeIcon size="3x" icon="user-shield" />
            </div>
            <div className="form-controls">
              <input
                ref={nameRef}
                className="form-control"
                type="text"
                name="name"
                placeholder="Λογαριασμός χρήστη"
                onChange={(event) => {
                  setUsername(event.target.value);
                }}
              />
              <input
                className="form-control"
                type="password"
                name="password"
                placeholder="Κωδικός"
                onChange={(event) => {
                  setPassword(event.target.value);
                }}
              />
            </div>
            <div className="form-buttons">
              <Button color="primary" type="submit">
                Σύνδεση {loggingIn && <FontAwesomeIcon icon="spinner" spin />}
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </Container>
  );
  // <FormGroup switch>
  //     <Input
  //         id="rememberme"
  //         type="switch"
  //         role="switch"
  //         name="rememberme"
  //         onChange={(event) =>
  //             setRememberMe(event.target.value)
  //         }
  //     />
  //     <Label htmlFor="rememberme" check>
  //         Να με θυμάσαι
  //     </Label>
  // </FormGroup>
};

export default LoginForm;
