import React, { useState, useEffect, useContext } from "react";
import {
    Button,
    FormGroup,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import Autocomplete from "react-autocomplete";
import "./SalesmanAssignmentDialog.css";
import { Context } from "../../Store";

const SalesmanAssignmentDialog = ({ record, isOpen, closeFn, saveFn }) => {
    const [state] = useContext(Context);
    const [area, setArea] = useState("");
    const [allAreas, setAllAreas] = useState([]);
    const [city, setCity] = useState("");
    const [allCities, setAllCities] = useState([]);
    const [nomos, setNomos] = useState("");
    const [allNomoi, setAllNomoi] = useState([]);
    const [po, setPO] = useState("");
    const [allPOs, setAllPOs] = useState([]);

    const doClose = () => {
        setArea("");
        setCity("");
        setNomos("");
        setPO("");
        closeFn();
    };

    // set initial values
    useEffect(() => {
        setArea(record?.area);
        setCity(record?.city);
        setNomos(record?.nomos);
        setPO(record?.postalCode);

        filterAreas(record?.area);
        filterCities(record?.city);
        filterNomoi(record?.nomos);
        filterZipcodes(record?.postalCode);
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            record?.area, record?.city, record?.nomos, record?.postalCode,
        ]
    );

    const filterAreas = (v) => {
        const lcase = v?.toLocaleLowerCase();
        setAllAreas(
            lcase
                ? state.areas.filter((item) =>
                    item.toLocaleLowerCase().startsWith(lcase)
                )
                : state.areas
        );
    };

    const filterCities = (v) => {
        const lcase = v?.toLocaleLowerCase();
        setAllCities(
            lcase
                ? state.cities.filter((item) =>
                    item.toLocaleLowerCase().startsWith(lcase)
                )
                : state.cities
        );
    };

    const filterNomoi = (v) => {
        const lcase = v?.toLocaleLowerCase();
        setAllNomoi(
            lcase
                ? state.nomoi.filter((item) =>
                    item.toLocaleLowerCase().startsWith(lcase)
                )
                : state.nomoi
        );
    };

    const filterZipcodes = (v) => {
        setAllPOs(
            v ? state.zipcodes.filter((item) => item.startsWith(v)) : state.zipcodes
        );
    };

    const autocompleteItem = (item, isHighlighted) => (
        <div style={{ background: isHighlighted ? "lightgray" : "white" }}>
            {item}
        </div>
    );

    return (
        <Modal isOpen={isOpen} toggle={doClose} size="sm">
            <ModalHeader toggle={doClose}>Ανάθεση σε πωλητή</ModalHeader>
            <ModalBody>
                <FormGroup className="mb-3">
                    <Autocomplete
                        id="area"
                        getItemValue={(item) => item}
                        items={allAreas}
                        inputProps={{ className: "form-control", placeholder: "Περιοχή" }}
                        renderItem={autocompleteItem}
                        wrapperProps={{ className: "autocomplete" }}
                        wrapperStyle={{ display: "block" }}
                        value={area}
                        onChange={(e) => {
                            filterAreas(e.target.value);
                            setArea(e.target.value);
                        }}
                        onSelect={(val) => setArea(val)}
                    />
                </FormGroup>
                <FormGroup>
                    <Autocomplete
                        id="city"
                        getItemValue={(item) => item}
                        items={allCities}
                        inputProps={{ className: "form-control", placeholder: "Πόλη" }}
                        renderItem={autocompleteItem}
                        wrapperProps={{ className: "autocomplete" }}
                        wrapperStyle={{ display: "block" }}
                        value={city}
                        onChange={(e) => {
                            filterCities(e.target.value);
                            setCity(e.target.value);
                        }}
                        onSelect={(val) => setCity(val)}
                    />
                </FormGroup>
                <FormGroup>
                    <Autocomplete
                        id="nomos"
                        getItemValue={(item) => item}
                        items={allNomoi}
                        inputProps={{ className: "form-control", placeholder: "Νομός" }}
                        renderItem={autocompleteItem}
                        wrapperProps={{ className: "autocomplete" }}
                        wrapperStyle={{ display: "block" }}
                        value={nomos}
                        onChange={(e) => {
                            filterNomoi(e.target.value);
                            setNomos(e.target.value);
                        }}
                        onSelect={(val) => setNomos(val)}
                    />
                </FormGroup>
                <FormGroup>
                    <Autocomplete
                        id="po"
                        getItemValue={(item) => item}
                        items={allPOs}
                        inputProps={{
                            className: "form-control",
                            placeholder: "Ταχυδρομικός κωδικός",
                        }}
                        renderItem={autocompleteItem}
                        wrapperProps={{ className: "autocomplete" }}
                        wrapperStyle={{ display: "block" }}
                        value={po}
                        onChange={(e) => {
                            filterZipcodes(e.target.value);
                            setPO(e.target.value);
                        }}
                        onSelect={(val) => setPO(val)}
                    />
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button
                    color="primary"
                    size="sm"
                    disabled={
                        !area?.length && !city?.length && !nomos?.length && !po?.length
                    }
                    onClick={() => {
                        saveFn(record?.id, area, city, nomos, po);
                        doClose();
                    }}
                >
                    Ανάθεση
                </Button>{" "}
                <Button size="sm" onClick={doClose}>
                    Ακύρωση
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default SalesmanAssignmentDialog;
