import React from "react";
import { FormGroup, Label } from "reactstrap";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

function DateFormField({ name, title, date, setDateFn, disabled = false }) {
  return (
    <FormGroup>
      <Label for={name} size="sm">
        {title}
      </Label>
      <DatePicker
        selected={new Date(date)}
        disabled={disabled}
        dateFormat="dd/MM/yyyy"
        // min={state.presetDates[0].start}
        // max={state.presetDates[0].end}
        bsSize="sm"
        onChange={d => setDateFn(d.toISOString().slice(0, 10))}
      />
    </FormGroup>
  );
}

export default DateFormField;
