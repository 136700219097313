import React, { useContext, useState, useEffect } from "react";
import { Container, Row, Col, FormGroup, Input } from "reactstrap";
import { SiteMenu } from "../menu"
import { Context } from "../Store";

function User() {
    const [state] = useContext(Context);
    const [startPage, setStartPage] = useState("");

    let defaultStartPage = "/tr/total"
    if (state.is_admin) {
        defaultStartPage = "/dashboard"
    }

    const setPage = (v) => {
        setStartPage(v)
        localStorage.setItem("startPage", v)
    }

    useEffect(() =>
        setStartPage(localStorage.getItem("startPage") || defaultStartPage),
        [defaultStartPage, setStartPage]
    )

    return (
        <Container fluid>
            <h2 className="page-header">Προτιμήσεις</h2>
            <Row>
                <Col md={2}>
                    Αρχική σελίδα:
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Input
                            type="select"
                            name="startPage"
                            id="startPage"
                            bsSize="sm"
                            value={startPage}
                            onChange={(e) => setPage(e.target.value)}
                        >
                            {SiteMenu.map((m) => {
                                return m.entries.map((i) => {
                                    return <option value={i.path}>{m.name}: {i.name}</option>
                                })
                            })}
                        </Input>
                    </FormGroup>
                </Col>
            </Row>
        </Container>
    );
}

export default User;
