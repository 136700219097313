import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../../Store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Container, Table, Row, Col } from "reactstrap";
import ListHeader from "./header";
import ListRow from "./row";
import Cards from "./Cards";
import { usePagination } from "../../Pagination/usePagination";
import { exportExcel } from "./exporter";
import Pagination from "../../Pagination/Pagination";
import RadarData from "../../charts/RadarData";
import Spinner from "../../../Spinner";
import Filters from "../../filter/Filters";
import { useApiCall } from "../../../useApiCall";

function List() {
    const [state] = useContext(Context);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(15);
    const [transactions, setTransactions] = useState(null);
    const pagination = usePagination({
        currentPage,
        pageSize,
        records: transactions?.records,
    });
    const getTotalTransactionsPerCategory = useApiCall(
        "/total_transactions_per_category"
    );

    useEffect(() => {
        const abort = new AbortController();

        setTransactions(null);
        getTotalTransactionsPerCategory(
            JSON.stringify(
                {
                    ...state.filters,
                    user: { userIds: state.userIds },
                    database: state.database,
                    posit: false,
                },
                "",
                { signal: abort.signal }
            )
        ).then((resp) => setTransactions(resp));

        return () => abort.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        setTransactions,
        currentPage,
        pageSize,
        state.filters,
        state.userIds,
        state.access_token,
        state.database,
    ]);

    return (
        <Container fluid>
            <h2 className="page-header">Συνολικές συναλλαγές ανά κατηγορία</h2>

            <Cards transactions={transactions} />

            <Filters
                statistics={transactions?.statistics}
                transactionsVisible={false}
                locationVisible={false}
                searchVisible={false}
            />

            {!transactions && <Spinner />}
          {transactions && !transactions.records && <Spinner message="Δεν υπάρχουν εγγραφές" showSpinner={false}/>}

            {transactions?.records?.length && (
                <>
                    <Container fluid="sm">
                        <Row style={{ minHeight: 400, paddingTop: "1rem" }}>
                            <Col sm={5}>
                                <Table
                                    className="table-centered table-condensed"
                                    size="sm"
                                    striped
                                    hover
                                    responsive
                                >
                                    <ListHeader />
                                    <tbody>
                                        {pagination.records?.map((row) => {
                                            return (
                                                <ListRow
                                                    key={row.originalIndex}
                                                    data={row}
                                                />
                                            );
                                        })}
                                    </tbody>
                                </Table>
                            </Col>
                            <Col sm={7}>
                                <RadarData
                                    data={transactions?.records
                                        ?.map((entry) => {
                                            return {
                                                key: entry.name,
                                                value: entry.totalTransactions,
                                            };
                                        })
                                        .sort((e1, e2) => e1.value <= e2.value)}
                                />
                            </Col>
                        </Row>
                    </Container>

                    <div className="exporters-container">
                        <Button
                            size="sm"
                            onClick={() =>
                                exportExcel(
                                    "TotalTransactionsPerCategory.xlsx",
                                    state,
                                    transactions?.statistics,
                                    transactions?.records
                                )
                            }
                        >
                            <FontAwesomeIcon icon="save" /> Εξαγωγή σε Excel
                        </Button>
                    </div>

                    <Pagination
                        pagination={pagination}
                        currentPage={currentPage}
                        pageSize={pageSize}
                        onPageChange={setCurrentPage}
                        setPageSize={setPageSize}
                    />
                </>
            )}
        </Container>
    );
}

export default List;
