import React, { useContext } from "react";
import { Context } from "./Store";
import { config } from "./Config";
import { ADD_ALERT, DELETE_ALERT } from "./Reducer";
import { UncontrolledAlert } from "reactstrap";

export const useAlerts = () => {
  const [state, dispatch] = useContext(Context);

  const display = () => {
    if (state.alerts.length > 0) {
      return state.alerts.map((alrt, idx) => (
        <UncontrolledAlert
          key={idx}
          color={alrt.color}
          fade={true}
          toggle={() =>
            dispatch({
              type: DELETE_ALERT,
              payload: alrt.id,
            })
          }
        >
          <span className="mdi mdi-48px mdi-alert-octagon" />{" "}
          {alrt.title && <h5>{alrt.title}</h5>}
          {alrt.message}
        </UncontrolledAlert>
      ));
    }
  };

  const cleanup = () => {
    const now = Date.now();
    for (let i = 0; i < state.alerts.length; ++i) {
      const al = state.alerts[i];
      if (al.timeout > 0 && now - al._startTime >= al.timeout) {
        // alert done
        dispatch({
          type: DELETE_ALERT,
          payload: al.id,
        });
        break;
      }
    }
  };

  const newVersion = (msg) => {
    dispatch({
      type: DELETE_ALERT,
      payload: "new-version",
    });
    dispatch({
      type: ADD_ALERT,
      payload: {
        id: "new-version",
        title: `Νέα έκδοση, ${msg.payload}!`,
        message: `Ανιχνεύθηκε νέα έκδοση της εφαρμογής στον διακομιστή!\nΠατήστε F5 για αναβάθμιση τώρα από την ${config.VERSION} στην ${msg.payload}.`,
        color: "warning",
        timeout: 0,
      },
    });
  };

  return [display, cleanup, newVersion];
};
